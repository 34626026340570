import React, { useState, useEffect, useMemo } from "react";

import { CustomButton as Button } from "_components";
import { CFG_TR } from "_constants";
import { useAuth, useApp } from "_contexts";
import { isNonEmptyObject } from "_helpers";
import { useAppConfig } from "_hooks";
import { logout } from "_services";

import { message as Message } from "antd";

export function Homepage() {
    const [ componentIsReady, setComponentIsReady ] = useState(false);

    const { setUser } = useAuth();
    const { config, setConfig } = useApp();

    const { appData } = useAppConfig();

    const { cfg_gen, reporting, settings } = CFG_TR;

    useEffect(() => { 
        document.title = 'Espace client - Accueil';
        return () => setComponentIsReady(false);
    }, []);

    useEffect(() => {
        if( isNonEmptyObject(appData) ){
            setConfig(appData);
            setComponentIsReady(true);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appData])

    const handleClick = ( ) => {
        try{
            const timer = setTimeout(async () => {
                const { response } = await logout();

                if( response ){
                    Message.success({key: "invalidation", content: "Déconnecté !"});
                    setUser({ isAuth: false });
                }
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
    }

    const buttonsItems = useMemo(() => ([
        {
            key: 'dossiers',
            path: 'dossiers',
            title: 'Consulter mes dossiers',
            direction: 'left'
        },{
            key: 'reporting',
            path: 'reporting',
            title: 'Reporting',
            hidden: !config?.[cfg_gen]?.[reporting],
            direction: 'right'
        },{
            key: 'parametres',
            path: 'parametres',
            title: 'Paramètres',
            hidden: !config?.[cfg_gen]?.[settings],
            direction: 'left'
        },{
            key: 'disconnect',
            title: 'Déconnexion',
            logout: true,
            onClick: handleClick,
            direction: 'bottom'
        }
    ]
        .filter(el => !el.hidden)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [config])

    return(
        <div className="d-flex flex-column justify-content-center align-items-center" style={{height: 'calc(100% - 5px)'}}>
            {
                componentIsReady
                &&
                    buttonsItems.map(button => {
                        const { key, title, ...rest } = button;
                        return (
                            <Button 
                                key={key}
                                {...rest}
                            >
                                { title }
                            </Button>
                        );
                    })
            }
        </div>
    );
}