export function compareTextContains(filter, value, filterText){
    const filterTextLowerCase = filterText.toLowerCase(),
            valueLowerCase = value.toString().toLowerCase();

    switch (filter) {
        case 'contains':
            return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
        case 'notContains':
            return valueLowerCase.indexOf(filterTextLowerCase) === -1;
        case 'equals':
            return valueLowerCase === filterTextLowerCase;
        case 'notEqual':
            return valueLowerCase !== filterTextLowerCase;
        case 'startsWith':
            return valueLowerCase.indexOf(filterTextLowerCase) === 0;
        case 'endsWith':
            const index = valueLowerCase.lastIndexOf(filterTextLowerCase);
            return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
        default:
            // console.warn('invalid filter type ' + filter);
            return false;
    }
}