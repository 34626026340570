import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";

import { AgGridCustom as AgGrid, CustomSpin as Spin } from "_components";
import { GridContext, useInvoices } from "_contexts";
import { isNonEmptyArray, isUniqueValueArray, shallowCopy, deepCopy } from "_helpers";
import { useFolders } from "_hooks";

import { RechartsCustom as Recharts } from "./_Recharts";
import { gridOptions, columnDefs, createPinData } from "./_tableConfigs";
import { FolderDetail } from "./FolderDetail";

import { Col, Button, Divider, Row, Space, Tabs } from "antd";
import { Collapse } from "bootstrap";
import dayjs from "dayjs";

import { ReactComponent as Arrow } from "_assets/left-arrow-white.svg";
import { ReactComponent as Xlsx } from "_assets/xlsx-icon.svg";

import styles from "_styles/Folders.module.css";

let statusFilter = "all";
let stateFilter = "all";

export function Folders() {
    const [ gridData, setGridData ] = useState([]);
    const [ chartsData, setChartsData ] = useState({});

    const [ tabsActiveKey, setTabsActiveKey ] = useState('dossiers');

    const [ closedFoldersIsEnable, setClosedFoldersIsEnable ] = useState(false);

    const [ currentSelectedAccId, setCurrentSelectedAccId ] = useState("");

    const [ gridDataAreUpdated, setGridDataAreUpdated ] = useState(false);
    
    const { fetchGridData, fetchChartsData, fetchChartsLegend, fetchDataAreReady, fetchIsDone } = useFolders();
    const { litigMessages, setLitigMessages, setCommunicationsState } = useInvoices();

    const gridRef = useRef();

    useEffect(() => { document.title = 'Espace client - Dossiers' }, []);

    useEffect(() => {
        if( fetchDataAreReady ){
            let gridDataCopy = deepCopy(fetchGridData), chartsDataCopy = deepCopy(fetchChartsData);

            if( !closedFoldersIsEnable ){
                // On vide les valeurs des charts
                Object.values(chartsDataCopy).forEach(chart => {
                    chart.forEach(val => {
                        val.value = 0;
                    })
                })

                // On filtre sur ce qui nous intéresse
                gridDataCopy = gridDataCopy.filter(data => {
                    const { _status_closedEnd, _status_closedVoid } = data;
                    return !_status_closedEnd && !_status_closedVoid && !( _status_closedEnd && _status_closedVoid );
                })

                // On regénère les valeurs
                gridDataCopy.forEach(data => {
                    const { _status_id, inv_sumRest, status_color, status_txt } = data;

                    Object.entries(chartsDataCopy).forEach(([key, chart]) => {
                        chart.forEach(val => {
                            const { id } = val;

                            if( id === _status_id ){
                                if( key === 'chart01' ) val.value += inv_sumRest;
                                if( key === 'chart02' ) val.value += 1;

                            }
                        })
                    })
                })
                
            }
            setGridData(gridDataCopy);
            setChartsData(chartsDataCopy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closedFoldersIsEnable, fetchDataAreReady])

    useEffect(() => {
        if( isNonEmptyArray(litigMessages) ){
            let dataCopy = shallowCopy(gridData);
            const lastMessage = litigMessages[litigMessages.length-1];
            const { _file_id, msg_direction } = lastMessage;

            const index = dataCopy.findIndex(el => el._file_id === _file_id); 

            if( index !== -1 ){
                const { _status_closedEnd, _status_closedVoid, communication_state, acc_id } = dataCopy[index];
                if( !_status_closedEnd && !_status_closedVoid ){
                    const translate = {
                        in: 'to_treat',
                        out: 'pending'
                    }

                    if( translate[msg_direction] !== communication_state ){
                        dataCopy[index]['communication_state'] = translate[msg_direction];
                        setGridData(dataCopy);
                        setGridDataAreUpdated(true);
                    }
                }
            }
            setLitigMessages([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [litigMessages])

    useEffect(() => {
        redefineCommunicationsState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridData])

    const isExternalFilterPresent = useCallback(( ) => statusFilter !== "all" || stateFilter !== "all", [statusFilter, stateFilter])
    const doesExternalFilterPass = useCallback(({ data }) => {
        const { _status_id, communication_state } = data;
        
        if( statusFilter !== 'all' && stateFilter !== 'all' ){
            return _status_id === statusFilter &&
                communication_state === stateFilter;
        }
        if( statusFilter !== 'all' ){
            return _status_id === statusFilter;
        }
        if( stateFilter !== 'all' ){
            return communication_state === stateFilter;
        }
        
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilter, stateFilter])

    const handleRowDataIsUpdate = useCallback(({ newData }) => {
        // console.log(newData)
    }, [])

    const handleChartSectorClick = useCallback(({ statusId }) => {
        statusFilter = ( statusFilter !== statusId ) ? statusId : "all";
        gridRef.current.api.onFilterChanged();
    }, [])

    const handleRowDoubleClick = useCallback(({ accId }) => {
        // navigate(`/dossiers/${accId}`); // Ancienne méthode
        // handleAddTab(accId);
        setCurrentSelectedAccId(accId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const redefineCommunicationsState = useCallback(() => {
        if( isNonEmptyArray(gridData) ){
            const reduced = gridData.reduce((acc, val) => {
                const { communication_state, acc_id, _file_id } = val;

                if( communication_state ){
                    if( !acc[acc_id] ){
                        acc[acc_id] = [];
                    }
                    acc[acc_id].push({
                        file_id: _file_id,
                        communication_state
                    });
                }

                return acc;
            }, {});

            setCommunicationsState(reduced);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridData])

    const foldersData = {
        'fetchGridData': gridData,
        'fetchChartsData': chartsData,
        'fetchChartsLegend': fetchChartsLegend
    };

    // TABS MODE
    useEffect(() => {
        if( currentSelectedAccId ){
            handleAddTab(currentSelectedAccId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedAccId])

    const payload = {
        closedFoldersIsEnable,
        setClosedFoldersIsEnable,

        handleRowDataIsUpdate, 
        handleChartSectorClick, 
        handleRowDoubleClick,

        isExternalFilterPresent,
        doesExternalFilterPass
    };

    const tabsItemsInitialValues = useMemo(() => ([
        {
            key: 'dossiers',
            label: 'Dossiers',
            children: (
                <FoldersComponent 
                    gridRef={gridRef} 
                    foldersData={foldersData} 
                    payload={payload} 
                />
            ),
            closable: false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [gridData, chartsData, fetchChartsLegend])
    const [ tabsItems, setTabsItems ] = useState(tabsItemsInitialValues);

    useEffect(() => {
        if(
            isNonEmptyArray(gridData) &&
            isNonEmptyArray(chartsData?.chart01) &&
            isNonEmptyArray(chartsData?.chart02) &&  
            isNonEmptyArray(fetchChartsLegend)
        ){
            let tabsItemsCopy = shallowCopy(tabsItems);
            tabsItemsCopy = tabsItemsCopy.filter(el => el.key !== 'dossiers');
            setTabsItems([...tabsItemsInitialValues, ...tabsItemsCopy]);
            setTabsActiveKey(tabsItemsInitialValues[0]['key'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridData, chartsData, fetchChartsLegend])

    useEffect(() => {
        if( isUniqueValueArray(tabsItems) && currentSelectedAccId ){
            setCurrentSelectedAccId("");
        }
    }, [tabsItems, currentSelectedAccId])

    useEffect(() => {
        if( gridDataAreUpdated ){
            const tabsItemsCopy = shallowCopy(tabsItems);
            const index = tabsItemsCopy.findIndex(t => t.key === tabsActiveKey);
    
            if( index !== -1 ){
                setTabsActiveKey(tabsItemsCopy[index]['key']);
            }
        }
        setGridDataAreUpdated(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridDataAreUpdated])

    const handleTabsChange = ( newKey ) => {
        setTabsActiveKey(newKey);
    }

    const handleAddTab = ( newId ) => {
        const tabsItemsCopy = shallowCopy(tabsItems);
        const index = tabsItemsCopy.findIndex(el => el.key === newId);

        if( index === -1 ){
            setTabsItems(oldVal => ([...oldVal, {
                key: newId,
                label: newId,
                children: (<FolderDetail acc_id={newId} />)
            }]));
            setTabsActiveKey(newId);
        } else {
            setTabsActiveKey(tabsItemsCopy[index]['key']);
        }
    }

    const handleRemoveTab = ( targetKey ) => {
        const targetIndex = tabsItems.findIndex((pane) => pane.key === targetKey);
        const newPanes = tabsItems.filter((pane) => pane.key !== targetKey);
        if (newPanes.length && targetKey === tabsActiveKey) {
            const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
            setTabsActiveKey(key);
        }

        setTabsItems(newPanes);
    };

    return(
        <div className="mt-1" style={{height: 'calc(100% - 32px)'}}> 
            {
                fetchDataAreReady && fetchIsDone
                ?
                    <Tabs 
                        activeKey={tabsActiveKey}
                        items={tabsItems} 
                        type="editable-card" 
                        className={["h-100", styles.tabsHeight].join(" ")}
                        hideAdd
                        onChange={handleTabsChange} 
                        onEdit={handleRemoveTab}
                    />
                :
                !fetchDataAreReady && fetchIsDone
                ?
                    <div className="d-flex justify-content-center align-items-center h-100">Aucunes données à afficher</div>
                :
                    <Spin className="h-100" />
            }
        </div>
    );
}

function FoldersComponent({ gridRef, foldersData, payload }){
    const [ panelIsOpen, setPanelIsOpen ] = useState(true);

    const [ toTreatIsEnable, setToTreatIsEnable ] = useState(false);

    const { fetchGridData, fetchChartsData, fetchChartsLegend } = foldersData;

    const { 
        closedFoldersIsEnable,
        setClosedFoldersIsEnable,

        handleChartSectorClick, 
        handleRowDataIsUpdate, 
        handleRowDoubleClick,

        isExternalFilterPresent,
        doesExternalFilterPass
    } = payload;

    const myCollapse = document.getElementById('collapseTarget');

    useEffect(() => {
        if( myCollapse ){
            const bsCollapse = new Collapse(myCollapse, {toggle: false})
            panelIsOpen ? bsCollapse.show() : bsCollapse.hide()
        }
    }, [myCollapse, panelIsOpen])

    const handleFilterOnState = useCallback(( stateId ) => {
        stateFilter = ( stateFilter !== stateId ) ? stateId : "all";
        gridRef.current.api.onFilterChanged();
        setToTreatIsEnable(oldVal => !oldVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const today = useMemo(() => ( dayjs().format('YYYYMMDD_HHmmss') ), [])

    const xlsxParams = useCallback(() => {
        return {
            fileName: 'Dossiers - ' + today,
            sheetName: 'Espace client - Dossiers',
            processCellCallback: (params) => {
                const colDef = params.column.getColDef();
                // try to reuse valueFormatter from the colDef
                if( colDef.valueFormatter && colDef.field == 'communication_state' ) {
                    const valueFormatterParams = {
                        ...params,
                        data: params.node.data,
                        node: params.node,
                        colDef: params.column.getColDef()
                    };
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel(xlsxParams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const iconsSize = useMemo(() => ({ height: 18, width: 18 }), []);
    const { height, width } = iconsSize;

    return(
        <div className="h-100">
            <Row className="mb-2 justify-content-end">
                <Space className="d-flex align-items-center">
                    <Button
                        icon={<Xlsx height={height} width={width}/>}
                        styles={{
                            icon: { marginRight: '2px' }
                        }}
                        type="text"
                        onClick={() => onBtnExport()}
                    >
                        Exporter
                    </Button>
                    <Button type={toTreatIsEnable ? 'primary' : undefined} onClick={() => handleFilterOnState('to_treat')}>
                        A traiter
                    </Button>
                    <Button type={closedFoldersIsEnable ? 'primary' : undefined} onClick={() => setClosedFoldersIsEnable(!closedFoldersIsEnable)}>
                        Dossiers fermés
                    </Button>
                </Space>
            </Row>
            <Row gutter={16} style={{height: 'calc(100% - 34px)'}}>
                <Col className="h-100 collapse collapse-horizontal" id="collapseTarget" flex={panelIsOpen ? '550px' : 'none'}>
                    <Recharts 
                        fetchData={fetchChartsData} 
                        fetchLegend={fetchChartsLegend}
                        panelIsOpen={panelIsOpen}
                        callBacks={{
                            onChartSectorClick: handleChartSectorClick
                        }}
                    />
                </Col>
                <Col flex="50px" className="d-flex justify-content-center h-100">
                    <Row className="flex-column align-items-center">
                        <Divider type="vertical" className="mb-2" style={{height: "15px"}}/>
                            <Button 
                                type="primary"
                                className={styles.collapseButton}
                                shape="circle"
                                icon={<Arrow className={styles.svgIcon} height={height} width={width} viewBox="-3 0 20 20" transform={!panelIsOpen ? "rotate(180)" : undefined}/>}
                                onClick={() => setPanelIsOpen(!panelIsOpen)} 
                            />
                        <Divider type="vertical" className="mt-2" style={{flex: 1}}/>
                    </Row>
                </Col>
                <Col flex="auto" className="h-100">
                    <GridContext.Provider value={{ gridOptions, isExternalFilterPresent, doesExternalFilterPass }}>
                        <AgGrid
                            gridRef={gridRef} 
                            columnDefs={columnDefs}
                            createPinData={createPinData}
                            fetchData={fetchGridData}
                            callBacks={{
                                onRowDataIsUpdate: handleRowDataIsUpdate,
                                onRowDoubleClick: handleRowDoubleClick
                            }}
                        />
                    </GridContext.Provider>
                </Col>
            </Row>
        </div>
    );
}