export function sortDates(dateA, dateB){
    const aa = dateA.split('/').reverse().join(),
        bb = dateB.split('/').reverse().join();

    return aa < bb ? -1 : (aa > bb ? 1 : 0);
}

export function sortNumbers(numberA, numberB){
    return numberA - numberB;
}

export function sortStrings(stringA, stringB){
    return stringA.localeCompare(stringB);
}