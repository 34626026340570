import React, { useState, useMemo, useCallback, useEffect } from "react";

import { InputByType as Input } from "_components";
import { useApp } from "_contexts";
import { isNonEmptyObject, isNonEmptyArray, shallowCopy, sortStrings, arraysAreEquals } from "_helpers";

import { Button, Card, Col, Divider, message, Row, Space, Typography } from "antd";

import { ReactComponent as Check } from "_assets/check.svg";
import { ReactComponent as Cross } from "_assets/cross.svg";

export const SettingsSendingPreferences = ({ valuesHandler, setRequestIsSubmitted, activeKey }) => {
    const [ currentAddRecipientId, setCurrentAddRecipientId ] = useState(-1);
    const [ currentAddCustomReportingId, setCurrentAddCustomReportingId ] = useState(-1);

    const { values, setValues } = valuesHandler;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [ initialValues, setInitialValues ] = useState(values);

    const { Text } = Typography;
    const [ messageApi, contextHolder ] = message.useMessage();

    const { config } = useApp(),
        socsTranslate = config.cfg_socs;

    const periodicityOptions = useMemo(() => ([
        {
            label: 'Hebdomadaire',
            value: 'weekly'
        },
        {
            label: 'Mensuelle',
            value: 'monthly'
        },
        {
            label: 'Trimestrielle',
            value: 'quarterly'
        }
    ]), [])

    const iconDimensions = useMemo(() => ({
        width: 18,
        height: 18
    }), []);
    const { width, height } = iconDimensions;

    useEffect(() => {
        handleResetOthersCurrentAction();
        if( !arraysAreEquals(values?.formats, initialValues?.formats) ){
            let valuesCopy = shallowCopy(values);
            valuesCopy['formats'] = initialValues['formats'];
            setValues(valuesCopy);
        }
    }, [activeKey])

    const handlePeriodicityChange = ({ name, value }) => {
        setValues(oldVal => ({ ...oldVal, [name]: value }));
        setRequestIsSubmitted(true);
    }

    const handleFormatsChange = ({ name, checked }) => {
        const { formats } = values;

        handleResetOthersCurrentAction();

        if( checked ){
            setValues(oldVal => ({
                ...oldVal,
                formats: [...formats, name]
            }))
        } else {
            setValues(oldVal => ({
                ...oldVal,
                formats: formats.filter(e => e !== name)
            }))
        }
    }
    const handleSaveFormats = () => {
        setRequestIsSubmitted(true);
        setInitialValues(values);
    }

    const recipientRegex = useMemo(() => ( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ), []);
    const recipientError = useCallback(( message ) => messageApi.error(message), []);
    const recipientIsValid = useCallback((id) => {
        const findIndex = values['recipients'].findIndex(el => el === values['recipients'][id] );

        if( values['recipients'][id] ){
            if( values['recipients'][id].match(recipientRegex) ){
                if( findIndex !== -1 && findIndex === id ){
                    return true;
                }
                recipientError('L\'adresse e-mail est déjà présente dans la liste');
                return false;
            }
            recipientError('L\'adresse e-mail doit être au format example@test.com');
            return false;
        } 
        recipientError('L\'adresse e-mail n\'est pas renseignée');
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])
    
    const handleAddRecipient = useCallback(() => {
        let valuesCopy = shallowCopy(values);
        const currentId = valuesCopy['recipients'].length;

        handleResetOthersCurrentAction();
        if( !arraysAreEquals(values?.formats, initialValues?.formats) ){
            valuesCopy['formats'] = initialValues['formats'];
        }

        if( recipientIsValid(currentId -1) ){
            if(currentId > 1 && currentAddRecipientId !== -1){
                setRequestIsSubmitted(true);
            } 
            setCurrentAddRecipientId(currentId);

            valuesCopy['recipients'].push('');
        } 

        setValues(valuesCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, currentAddRecipientId])
    const handleRecipientsChange = ({ name, value }) => {
        const idx = name.split('_')[1];
        let valuesCopy = shallowCopy(values);
        valuesCopy['recipients'][idx] = value;
        setValues(valuesCopy);
    }
    const handleSaveRecipient = useCallback(( id ) => {
        if( recipientIsValid(id) ){
            setCurrentAddRecipientId(-1);
            setRequestIsSubmitted(true);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])
    const handleRemoveRecipient = ( id ) => {
        let valuesCopy = shallowCopy(values);
        if( valuesCopy['recipients'][id].match(recipientRegex) ){
            setRequestIsSubmitted(true);
        }
        valuesCopy['recipients'].splice(id, 1);
        setCurrentAddRecipientId(-1);
        setValues(valuesCopy);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const customReportingError = useCallback((message) => messageApi.error(message), []);
    const customReportingElementIsValid = useCallback((id) => {
        return values['customReporting'][id]?.['title'] && 
            isNonEmptyObject(values['customReporting'][id]?.['body']) && 
            Object.keys(values['customReporting'][id]?.['body']).length === 3 &&
            Object.values(values['customReporting'][id]?.['body']).every(e => isNonEmptyArray(e))
    }, [values])
    const customReportingIsValid = useCallback((id) => {
        let findIndex = values['customReporting'].findIndex(el => el.title && el.title.toLowerCase() === values['customReporting'][id]?.['title'].toLowerCase());

        if( values['customReporting'][id] ){
            if( customReportingElementIsValid(id) ){
                if( id === 0 ) return true;

                if( findIndex !== -1 && findIndex === id ){
                    return true;
                    // findIndex = values['customReporting'].findIndex(el => arraysAreEquals(el.body?.['entities'], values['customReporting'][id]['body']?.['entities']));
                    // if( findIndex === -1 || findIndex === id ){
                    //     return true;
                    // }
                    // customReportingError('Un reporting personnalisé ayant les mêmes entités existe déjà');
                    // return false;
                }
                customReportingError('Un reporting personnalisé portant le même nom existe déjà');
                return false;
            }
            customReportingError('Tous les champs du reporting personnalisé sont obligatoires et ne peuvent être vides');
            return false;
        } 
        customReportingError('Le reporting personnalisé est invalide');
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    const handleAddCustomReporting = useCallback(() => {
        let valuesCopy = shallowCopy(values);
        const currentId = valuesCopy['customReporting'].length;

        handleResetOthersCurrentAction();
        if( !arraysAreEquals(values?.formats, initialValues?.formats) ){
            valuesCopy['formats'] = initialValues['formats'];
        }

        if( currentId === 0 || customReportingIsValid(currentId -1) ){
            if( currentId !== 0 && currentAddCustomReportingId !== -1 ){
                setRequestIsSubmitted(true);
            }
            setCurrentAddCustomReportingId(currentId);

            valuesCopy['customReporting'].push({
                title: '',
                body: {}
            });
        }

        setValues(valuesCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, currentAddCustomReportingId])
    const handleCustomReportingChange = ({ name, value }) => {
        const nameSplitted = name.split('_'),
            baseName = nameSplitted[0];
        let idx = nameSplitted[1],
            extendedName = null;
        if( baseName === 'body' ){
            extendedName = nameSplitted[1];
            idx = nameSplitted[2];
        } 

        let valuesCopy = shallowCopy(values);

        if( extendedName ){
            valuesCopy['customReporting'][idx][baseName][extendedName] = value;
        } else {
            valuesCopy['customReporting'][idx][baseName] = value;
        }
        setValues(valuesCopy);
    }
    const handleSaveCustomReporting = useCallback(( id ) => {
        if( customReportingIsValid(id) ){
            setCurrentAddCustomReportingId(-1);
            setRequestIsSubmitted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])
    const handleRemoveCustomReporting = ( id ) => {
        let valuesCopy = shallowCopy(values);
        if( customReportingElementIsValid(id) ){
            setRequestIsSubmitted(true);
        }
        valuesCopy['customReporting'].splice(id, 1);
        setCurrentAddCustomReportingId(-1);
        setValues(valuesCopy);
    }

    const handleResetOthersCurrentAction = useCallback(( ) => {
        if( currentAddRecipientId !== -1 || currentAddCustomReportingId !== -1 ){
            let valuesCopy = shallowCopy(values);

            if( currentAddRecipientId !== -1 ){
                if( !valuesCopy['recipients'][currentAddRecipientId] ){
                    valuesCopy['recipients'].splice(currentAddRecipientId, 1);
                }
                setCurrentAddRecipientId(-1);
            } else if( currentAddCustomReportingId !== -1 ){
                if( !customReportingElementIsValid(currentAddCustomReportingId) ){
                    valuesCopy['customReporting'].splice(currentAddCustomReportingId, 1);
                }
                setCurrentAddCustomReportingId(-1);
            }

            setValues(valuesCopy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAddRecipientId, currentAddCustomReportingId, values])

    const formatsTranslate = useMemo(() => ({
        report_pdf: 'Reporting (PDF)',
        report_xlsx: 'Reporting (XLSX)',
        ci_pdf: 'CI (PDF)',
        ci_xlsx: 'CI (XLSX)',
    }), [])

    return (
        <>
            { contextHolder }
            <Space direction="vertical" className="w-100">
                <Input 
                    label="Périodicité"
                    type="select"
                    name="periodicity"
                    value={values.periodicity}
                    options={periodicityOptions}
                    onChange={handlePeriodicityChange}
                    style={{ width: '150px' }}
                />
                <Text>Formats :</Text>
                <Row gutter={[16,16]}>
                    <Col>
                        <Row justify="center" align="middle" className="flex-column">
                            <Text>Reporting</Text>
                            <Space>
                                <Input
                                    type="checkbox"
                                    label="PDF"
                                    name="report_pdf"
                                    checked={values.formats.includes('report_pdf')}
                                    onChange={handleFormatsChange}
                                />
                                <Input
                                    type="checkbox"
                                    label="XLSX"
                                    name="report_xlsx"
                                    checked={values.formats.includes('report_xlsx')}
                                    onChange={handleFormatsChange}
                                />
                            </Space>
                        </Row>
                    </Col>
                    <Col>
                        <Divider type="vertical" className="h-100" />
                    </Col>
                    <Col>
                        <Row justify="center" align="middle" className="flex-column">
                            <Text>Certificats d'irrécouvrabilité</Text>
                            <Space>
                                <Input
                                    type="checkbox"
                                    label="PDF"
                                    name="ci_pdf"
                                    checked={values.formats.includes('ci_pdf')}
                                    onChange={handleFormatsChange}
                                />
                                <Input
                                    type="checkbox"
                                    label="XLSX"
                                    name="ci_xlsx"
                                    checked={values.formats.includes('ci_xlsx')}
                                    onChange={handleFormatsChange}
                                />
                            </Space>
                        </Row>
                    </Col>
                    {
                        !arraysAreEquals(values?.formats, initialValues?.formats)
                        &&
                            <Col flex='32px'>
                                <Button 
                                    className="h-100" 
                                    icon={<Check height={height} width={width} />} 
                                    style={{ background: 'green', borderColor: 'green' }} 
                                    onClick={handleSaveFormats}
                                />
                            </Col>
                    }
                </Row>
                <Text>Destinataires :</Text>
                <ul className="mb-0">
                    {
                        values['recipients'].map((el, idx) => {
                            return (
                                <li key={idx} className="mb-1">
                                    <Row gutter={4}>
                                        {
                                            idx === 0 // Email par défaut, ne doit pas être supprimé
                                            ?
                                                <Col>
                                                    <Text className="d-flex align-items-center h-100">{el}</Text>
                                                </Col>
                                            :
                                            currentAddRecipientId !== -1 && currentAddRecipientId === idx
                                            ?
                                                <>
                                                    <Col flex="302px">
                                                        <Input 
                                                            name={'recipient_'+idx}
                                                            value={values['recipients'][idx]}
                                                            onChange={handleRecipientsChange}
                                                        />
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Check height={height} width={width} />} 
                                                            style={{ background: 'green', borderColor: 'green' }} 
                                                            onClick={() => handleSaveRecipient(idx)}
                                                        />
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Cross height={height} width={width} />} 
                                                            style={{ background: 'red', borderColor: 'red' }} 
                                                            onClick={() => handleRemoveRecipient(idx)}
                                                        />
                                                    </Col>
                                                </>
                                            :
                                                <>
                                                    <Col flex="338px">
                                                        <Text className="d-flex align-items-center h-100">{el}</Text>
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Cross height={height} width={width} />} 
                                                            style={{ background: 'red', borderColor: 'red' }} 
                                                            onClick={() => handleRemoveRecipient(idx) }
                                                        />
                                                    </Col>
                                                </>
                                        }
                                    </Row>
                                </li>
                            );
                        })
                    }
                </ul>
                <Button type="primary" style={{ width: '400px' }} onClick={handleAddRecipient}>Ajouter un destinataire</Button>
                <Text>Reporting(s) personnalisé(s) :</Text>
                <Row gutter={[8,16]}>
                    {
                        values['customReporting'].map((el, idx) => {
                            const { title, body } = el;
                            return (
                                <Col xs={24} md={12} lg={8} key={idx}>
                                    <Row gutter={8} className="h-100">
                                        {
                                            currentAddCustomReportingId !== idx
                                            ?
                                                <>
                                                    <Col flex='1 1'>
                                                        <Card title={title} className="h-100">
                                                            <Space direction='vertical' size={22} className='w-100'>
                                                                {
                                                                    Object.entries(body)
                                                                        .sort(([aKey], [bKey]) => {
                                                                            const order = ['entities', 'formats', 'recipients'];
                                                                            const aIdx = order.findIndex(e => e === aKey);
                                                                            const bIdx = order.findIndex(e => e === bKey);

                                                                            if( aIdx < bIdx ) return -1;
                                                                            if( aIdx > bIdx ) return 1;
                                                                            return 0;
                                                                        })
                                                                        .map(([key, val]) => {
                                                                            return (
                                                                                <Row key={key}>
                                                                                    <Col xs={4}>
                                                                                        <Text>
                                                                                            {
                                                                                                key === 'entities'
                                                                                                ?
                                                                                                    'Sur :'
                                                                                                :
                                                                                                key === 'formats'
                                                                                                ?
                                                                                                    'En :'
                                                                                                :
                                                                                                key === 'recipients'
                                                                                                &&
                                                                                                    'Pour :'
                                                                                            }
                                                                                        </Text>
                                                                                    </Col>
                                                                                    <Col xs={20}>
                                                                                        {
                                                                                            key === 'formats'
                                                                                            ?
                                                                                                val
                                                                                                    .map(el => formatsTranslate[el])
                                                                                                    .join(', ')
                                                                                            :
                                                                                            key === 'entities'
                                                                                            ?
                                                                                                val
                                                                                                    .map(el => socsTranslate[el])
                                                                                                    .join(' // ')
                                                                                            :
                                                                                                val.join(', ')
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            );
                                                                    })
                                                                }
                                                            </Space>
                                                        </Card>
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Cross height={height} width={width} />} 
                                                            style={{ background: 'red', borderColor: 'red' }} 
                                                            onClick={() => handleRemoveCustomReporting(idx) }
                                                        />
                                                    </Col>
                                                </>
                                            :
                                            currentAddCustomReportingId !== -1 && currentAddCustomReportingId === idx
                                            &&
                                                <>
                                                    <Col flex='1 1'>
                                                        <Card 
                                                            title={
                                                                <Row align='middle'>
                                                                    <Col xs={10}>
                                                                        <Text>Nom du reporting :</Text>
                                                                    </Col>
                                                                    <Col xs={14}>
                                                                        <Input 
                                                                            name={'title_' + idx}
                                                                            onChange={handleCustomReportingChange}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                        }
                                                        >
                                                            <Space direction='vertical' className='w-100'>
                                                                <Row align='middle'>
                                                                    <Col xs={10}>
                                                                        <Text>Entités :</Text>
                                                                    </Col>
                                                                    <Col xs={14}>
                                                                        <Input 
                                                                            type='select'
                                                                            name={'body_entities_' + idx}
                                                                            className='w-100'
                                                                            mode='multiple'
                                                                            options={Object.entries(socsTranslate)
                                                                                .sort(([aKey, aVal],[bKey, bVal]) => sortStrings(aVal,bVal))
                                                                                .map(([key, val]) => {
                                                                                    return { label: val, value: key }
                                                                                })}
                                                                            onChange={handleCustomReportingChange}    
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row align='middle'>
                                                                    <Col xs={10}>
                                                                        <Text>Formats :</Text>
                                                                    </Col>
                                                                    <Col xs={14}>
                                                                        <Input 
                                                                            type='select'
                                                                            name={'body_formats_' + idx}
                                                                            className='w-100'
                                                                            mode='multiple'
                                                                            options={initialValues.formats
                                                                                .map(el => {
                                                                                    return { label: formatsTranslate[el], value: el }
                                                                            })}
                                                                            onChange={handleCustomReportingChange}    
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row align='middle'>
                                                                    <Col xs={10}>
                                                                        <Text>Destinataires :</Text>
                                                                    </Col>
                                                                    <Col xs={14}>
                                                                        <Input 
                                                                            type='select'
                                                                            name={'body_recipients_' + idx}
                                                                            className='w-100'
                                                                            mode='multiple'
                                                                            options={values.recipients
                                                                                .filter(el => el)
                                                                                .map(el => {
                                                                                    return { label: el, value: el }
                                                                            })}
                                                                            onChange={handleCustomReportingChange}    
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Space>
                                                        </Card>
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Check height={height} width={width} />} 
                                                            style={{ background: 'green', borderColor: 'green' }} 
                                                            onClick={() => handleSaveCustomReporting(idx)}
                                                        />
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Cross height={height} width={width} />} 
                                                            style={{ background: 'red', borderColor: 'red' }} 
                                                            onClick={() => handleRemoveCustomReporting(idx)}
                                                        />
                                                    </Col>
                                                </>
                                        }
                                    </Row>
                                </Col>
                            );
                        })
                    }
                </Row>
                <Button type="primary" className="w-100" onClick={handleAddCustomReporting}>Créer nouveau reporting</Button>
            </Space>
        </>
    );
}