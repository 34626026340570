import React, { useMemo, useCallback } from "react";

import { useApp } from "_contexts";
import { createFileDownloadRequest } from "_helpers";

import { Button, Table } from "antd";

import { ReactComponent as Download } from "_assets/settings/download.svg";

import styles from "_styles/Settings.module.css";
import dayjs from "dayjs";

export const SettingsIrrecoverabilityCertificates = ({ irrecCerts }) => {
    const { config } = useApp(),
        socsTranslate = config.cfg_socs;

    const formatFR = useMemo(() => ( 'DD/MM/YYYY' ), []);
    const formatFullFR = useMemo(() => ( 'DD/MM/YYYY HH:mm:ss' ), []);

    const handleDownloadAttachment = useCallback(( id, filename_txt ) => {
        createFileDownloadRequest('settings_getCi', { id })
            .then(res => res.data)
            .then(data => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename_txt);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })  
            .catch(err => console.log(err))
    }, [])

    const columns = useMemo(() => ([
        {
            title: 'Date d\'édition',
            dataIndex: 'edit_date',
            key: '_filename',
            width: '15%'
        },
        {
            title: 'Fichier',
            dataIndex: 'filename_txt',
            key: '_filename',
            width: '30%'
        },
        {
            title: 'Entités concernées',
            dataIndex: 'entities',
            key: '_filename',
            width: '49%'
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '6%',
            render: (_, { key, filename_txt, children }) => {
                return (
                    <>
                        {
                            children
                            ?
                                <></>
                            :   
                                <Button type="primary" icon={<Download height={18} width={18}/>} onClick={() => handleDownloadAttachment(key, filename_txt)} />
                        }
                    </>
                )
            }
        }
    ]), [])
    const data = useMemo(() => (
        Object.values(irrecCerts
            .reduce((acc, val) => {
                let { _filename, edit_date, filename_txt, entities } = val;
                const key = dayjs(edit_date).format(formatFR);
                entities = entities
                    .map(el => socsTranslate[el])
                    .join(', ')
                const objToPush = {
                    key: _filename,
                    edit_date: dayjs(edit_date).format(formatFullFR),
                    filename_txt: filename_txt,
                    entities: entities,
                };


                if( !acc[key] ){
                    acc[key] = {
                        key: key,
                        edit_date: key,
                        filename_txt: null,
                        entities: null,
                        children: []
                    }
                    acc[key]['children'].push(objToPush);
                } else {
                    acc[key]['children'].push(objToPush);
                }

                return acc;
            }, {}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [irrecCerts])

    return (
        <Table
            columns={columns}
            dataSource={data}
            scroll={{ y: 850 }}
            pagination={false}
            className={styles.customTable}
        />
    );
}