import { createContext, useContext, useState } from "react";

const InvoicesContext = createContext();

export const useInvoices = () => useContext(InvoicesContext);

export const InvoicesProvider = ({ children }) => {
    const [ litigMessages, setLitigMessages ] = useState([]);
    const [ communicationsState, setCommunicationsState ] = useState({})

    return(
        <InvoicesContext.Provider value={{ litigMessages, setLitigMessages, communicationsState, setCommunicationsState }}>
            {children}
        </InvoicesContext.Provider>
    );
}