import React, { useEffect, useState, useMemo, useCallback, useContext }  from "react";

import { 
    NoRowsOverlay, 
    LoadingOverlay, 
    AG_GRID_LOCALE_FR as localeText,
} from "_components";
import { GridContext } from "_contexts";
import { isNonEmptyArray, sortDates, sortNumbers, formatNumbers, formatWithTwoDecimals } from "_helpers";

import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const formatFR = "DD/MM/YYYY";

export const AgGridCustom = ({ gridRef, columnDefs, createPinData, createPinDataExtendedFields = [], fetchData, callBacks }) => {
    const [ rowData, setRowData ] = useState([]);

    const { gridOptions, isExternalFilterPresent, doesExternalFilterPass } = useContext(GridContext);

    const onRowDataIsUpdate = callBacks?.onRowDataIsUpdate;
    const onRowDoubleClick = callBacks?.onRowDoubleClick;

    useEffect(() => {
        setRowData(fetchData);
    }, [fetchData])

    const onGridReady = useCallback(( params ) => {
        // params.api.hideOverlay();
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pinnedTopRowData = useMemo(() => createPinData && createPinData(rowData, createPinDataExtendedFields), [rowData, createPinDataExtendedFields]);

    // UPDATE PINNED DATAS
    const onFilterChanged = useCallback(( params ) => {
        let result = [];

        params.api.forEachNodeAfterFilter(n => { result.push(n.data) });
        
        if( onRowDataIsUpdate ) onRowDataIsUpdate({ newData: result });

        if( createPinData ) params.api.setPinnedTopRowData(createPinData(result, createPinDataExtendedFields));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadingOverlayComponentParams = useCallback(( ) => ({
        loadingMessage: "Vos données se chargent, veuillez patienter..."
    }), [])
    
    const noRowsOverlayComponentParams = useCallback(( ) => ({
        loadingMessage: (
            <>
                <div>Désolé, nous ne sommes pas parvenus à charger vos données où aucunes données ne correspondent à votre demande,</div>
                <div>veuillez relancer une nouvelle requête</div>
            </>
        )
    }), [])

    return(
        <div className="ag-theme-alpine h-100">
            <AgGridReact
                ref={gridRef}
                rowData={isNonEmptyArray(rowData) ? rowData : null}
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                pinnedTopRowData={pinnedTopRowData}
                onFilterChanged={onFilterChanged}
                onCellDoubleClicked={({ data }) => onRowDoubleClick({ accId: data.acc_id })}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                loadingOverlayComponent={LoadingOverlay}
                loadingOverlayComponentParams={loadingOverlayComponentParams}
                noRowsOverlayComponent={NoRowsOverlay}
                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                columnTypes={AgGridGlobalColumnTypesCustom}
                excelStyles={AgGridGlobalExcelStylesCustom}
                getRowStyle={AgGridGlobalGetRowStyleCustom}
                localeText={localeText}
            />
        </div>
    );
}

const AgGridGlobalGetRowStyleCustom = ( params ) => {
    if ( params.node.rowPinned ) {
        return { 
            'fontWeight': 'bold'
        };
    }
};

const AgGridGlobalExcelStylesCustom = [
    {
        id: 'cell',
        alignment: { horizontal: 'Center' },
        dataType: 'String'
    },      
    {
        id: 'bold',
        font: {
            bold: true
        }
    },
    {
        id: 'dateExcelFormat',
        numberFormat: { format: 'dd/mm/yyyy' },
        dataType: 'DateTime'
    },
    {
        id: 'numberExcelFormat',
        numberFormat: { format: '#,##0.00' },
        alignment: { horizontal: 'Right' },
        dataType: 'Number'
    },
    {
        id: 'multiline',
        alignment: { horizontal: 'Left', vertical: 'Top', wrapText: true },
        dataType: 'String'
    }
];

const stateFormatter = ({ value }) => {
    switch( value ) {
        case 'new' : return 'Nouveau';
        case 'to_treat' : return 'A traiter';
        case 'pending' : return 'En attente';
        default : return null;
    }
}

const AgGridGlobalColumnTypesCustom = {
    centerAlign: {cellStyle: {'textAlign': 'center'}},
    valueColumn: {
        aggFunc: 'sum',
        valueParser: 'Number(newValue)',
        filter: 'agMultiColumnFilter',
        filterParams: {
            filters: [
                {
                    filter: 'agNumberColumnFilter', 
                    filterParams: {
                        buttons: ['reset']
                    }
                },
                {
                    filter: 'agSetColumnFilter', 
                    filterParams: {
                        comparator: sortNumbers,
                        valueFormatter: params => {
                            const value = formatWithTwoDecimals(params.value);

                            if( value === 0 ) return '0';
                            
                            return formatNumbers(value);
                        },
                        buttons: ['reset']
                    }
                },
            ], 
        },
        cellStyle: {'textAlign': 'right'},
        cellClass : 'numberExcelFormat',
        comparator: sortNumbers,
        valueFormatter: params => {
            const value = formatWithTwoDecimals(params.value);

            if( !value && params.node.rowPinned ) return null;

            if( value === 0 ){
                if( params.node.rowPinned ) return 0;
                return '';
            }

            return formatNumbers(value);
        }
    },
    dateColumn: {
        filter: 'agMultiColumnFilter',
        filterParams: {
            filters: [
                {
                    filter: 'agDateColumnFilter', 
                    filterParams: {
                        buttons: ['reset']
                    }
                },
                {
                    filter: 'agSetColumnFilter', 
                    filterParams: {
                        comparator: sortDates, // For filtering columns in agSetColumnFilter part
                        valueFormatter: params => params.value && dayjs(params.value).format(formatFR),
                        buttons: ['reset']
                    }
                },
            ]
        },
        cellStyle: {'textAlign': 'center'},
        cellClass: 'dateExcelFormat',
        comparator: sortDates, // For sorting columns
        valueFormatter: params => params.value && dayjs(params.value).format(formatFR),
    },
    commentColumn: {
        cellStyle: {'textAlign': 'left'},
        cellClass: 'multiline'
    },
    stateColumn: {
        filter: 'agSetColumnFilter', 
        filterParams: {
            valueFormatter: stateFormatter,
            buttons: ['reset']
        },
        cellStyle: { textAlign: 'center' },
        valueFormatter: stateFormatter
    }
};