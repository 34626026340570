import { useState, useEffect } from "react";

import { getAppConfig } from "_services";

export function useAppConfig(){
    const [ appData, setAppData ] = useState({});

    useEffect(() => {
        try {
            const timer = setTimeout(async () => {
                const { response, data } = await getAppConfig();

                if( response ){
                    setAppData(data);
                } else {
                    setAppData({});
                }
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            setAppData({});
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { appData };
}