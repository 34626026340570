import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

import { AppProvider, AuthProvider, ViewportProvider, InvoicesProvider } from "_contexts";

import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import 'dayjs/locale/fr';

import "bootstrap/dist/css/bootstrap.min.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'antd/dist/reset.css';
import "./_styles/Antd.css";
import "./custom.scss";
import "./App.css";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ConfigProvider 
        locale={frFR} 
        theme={{
            token: {
                fontFamily: 'Poppins, sans-serif',
                colorPrimary: '#0b6fba'
            }
        }}
    >
    <AppProvider>
    <AuthProvider>
    <ViewportProvider>
    <InvoicesProvider>
        <App/>
    </InvoicesProvider>
    </ViewportProvider>
    </AuthProvider>
    </AppProvider>
    </ConfigProvider>
);