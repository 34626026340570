import React, { useMemo } from 'react';

import { useViewport } from "_contexts";
import { formatNumbers } from "_helpers";

import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { Badge, Card, Col, Divider, Row, Space } from "antd";

export const CustomReportingCharts = ({ payload, panelIsOpen }) => {
    const { genChart, othChart, antChart } = payload;

    const { height } = useViewport();

    const chartsHeight = useMemo(() => {
        if( height < 945 ) return 870;
        if( height < 975 ) return 895;
        return 990;
    }, [height])

    const positions = useMemo(() => ({
        cx: '50%',
        cy: '50%'
    }), []);
    const { cx, cy } = positions;
    const props = useMemo(() => ({
        innerRadius: 60,
        outerRadius: 100,
        paddingAngle: 5,
        dataKey: 'value',
        startAngle: 90,
        endAngle: -360,
        isAnimationActive: false
    }), []);

    const charts = useMemo(() => ([
        {
            title: 'Décomposition du portefeuille confié',
            chart: genChart
        },
        {
            title: 'Portefeuille hors dossiers irrécouvrables, ayant fait l\'objet d\'OD/avoirs ou d\'une demande d\'arrêt relance',
            chart: othChart
        },
        {
            title: 'Antériorité des créances transmises',
            chart: antChart
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [payload])

    return (
        <>
            {
                panelIsOpen
                &&
                    charts.map(({ title, chart }, index) => (
                        <div key={index} style={{ height: chartsHeight/charts.length }}>
                            <div style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center'}}>
                                { title }
                            </div>
                            <Row align='middle'>
                                <Col xs={14}>
                                    <Card bodyStyle={{ padding: '10px' }}>
                                        <div>
                                            {
                                                chart?.data.map((data, idx) => {
                                                    const { id, name, value } = data;

                                                    return (
                                                        <Space key={id}>
                                                                <Badge color={chart?.colors[idx]} />
                                                                { name } - { formatNumbers(value, 0) } €
                                                        </Space>
                                                    );
                                                })
                                            }
                                        </div>
                                        <Divider className='my-2' />
                                        <div className='bld-txt d-flex justify-content-end'>
                                        { 'Total : ' + formatNumbers(chart?.total, 0) + ' €' }
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={10}>
                                    <div style={{ height: (chartsHeight/charts.length) - 25 + 'px' }}>
                                        <ResponsiveContainer>
                                            <PieChart>
                                                <Pie
                                                    data={chart?.data || []}
                                                    cx={cx}
                                                    cy={cy}
                                                    {...props}
                                                    >
                                                        {
                                                            chart?.data.map((_, i) => (
                                                                <Cell key={`cell-${i}`} fill={chart?.colors[i % chart?.colors.length]} />
                                                            ))
                                                        }
                                                </Pie>
                                                <Tooltip 
                                                    formatter={value => formatNumbers(value, 0) + ' €'}
                                                />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))
            }
        </>
    );
}