export function formatWithDecimals(val, dec = 2) {
    if( dec === 1 ) return Math.floor(parseFloat(val) * 10) / 10
    return Math.floor(parseFloat(val) * 100) / 100;
}

export function formatWithTwoDecimals(val) {
    return Math.floor(parseFloat(val) * 100) / 100;
}
 
export function formatNumbers( value, decimals = 2 ) {
    return value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatTextReplaceAccents(text){
    return text
        .replace(/\s/g, '')
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/\W/g, ''); 
}

export function formatToCapitalize( text ){
    return text.charAt(0).toUpperCase() + text.slice(1);
}