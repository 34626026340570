import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import { CustomRangePickerFooter as RangePickerFooter, InputByType as Input } from '_components';
import { isArray, isNonEmptyArray } from '_helpers';

import { Button, Col, Dropdown, Row, Space, Typography } from 'antd';

import { ReactComponent as Submit } from "_assets/reporting/submit.svg";
import { ReactComponent as Arrow } from "_assets/left-arrow-white.svg";
import { ReactComponent as Xlsx } from "_assets/xlsx-icon.svg";
import { ReactComponent as Pdf } from "_assets/pdf-icon.svg";

export const CustomReportingHeader = ({ gridRef, intervalHandler, resetData, onDownload, subComponentIsReady }) => {
    const { interval, setInterval } = intervalHandler;
    const [ value, setValue ] = useState([]);

    const { Text } = Typography;

    const formatFR = useMemo(() => ( 'DD/MM/YYYY' ), []);
    const iconSize = useMemo(() => ({ height: 18, width: 18 }), []);
    const { height, width } = iconSize;

    const timer1 = useRef();
    const timer2 = useRef();
    const timer3 = useRef();

    useEffect(() => {
        return () => {
            clearTimeout(timer1.current);
            clearTimeout(timer2.current);
            clearTimeout(timer3.current);
        }
    }, [])

    useEffect(() => {
        if( isNonEmptyArray(interval) && subComponentIsReady ){
            setValue(interval);
            timer1.current = setTimeout(() => {
                setInterval([]);
            },0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval, subComponentIsReady])

    const dropdownItems = useMemo(() => ([
        {
            key: 'xlsx',
            label: (
                <div className='d-flex justify-content-center' onClick={() => onDownload('xlsx')}>
                    <Space size={3}>
                        <Xlsx height={height} width={width} />
                        <span>Tableau</span>
                    </Space>
                </div>
            )
        },
        {
            key: 'pdf',
            label: (
                <div className='d-flex justify-content-center' onClick={() => onDownload('pdf')}>
                    <Space size={3}>
                        <Pdf height={height} width={width} />
                        <span>Rapport</span>
                    </Space>
                </div>
            ),
            hidden: true
        }
    ]), [])

    const handleChange = useCallback(({ value }) => {
        setValue(value);
    }, [])

    const handleSubmit = useCallback(( ) => {
        if( isArray(value) && isNonEmptyArray(value) && value.length === 2 ){
            resetData();
            timer2.current = setTimeout(() => {
                gridRef.current.api.showLoadingOverlay();
            }, 0)
            timer3.current = setTimeout(() => {
                setInterval(value); 
            }, 500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, gridRef])

    return (
        <>
            <Row>
                <Col className='ms-auto'>
                    <Space>
                        <Input 
                            type='rangepicker'
                            label={[
                                <Text key='interval'>
                                    Période du reporting
                                    <span className='red-txt bld-txt'>* </span>
                                </Text>
                            ]}
                            name='interval'
                            value={value}
                            format={formatFR}
                            renderExtraFooter={() => <RangePickerFooter name='interval' onChange={handleChange}/>}
                            onChange={handleChange}
                        />
                        <Button 
                            type="primary"
                            icon={<Submit height={height} width={width} viewBox="0 0 40 50"/>}
                            onClick={handleSubmit}
                        />
                    </Space>
                </Col>
                <Col className='ms-auto'>
                    <Dropdown menu={{items: dropdownItems}}>
                        <Button icon={<Arrow height={height} width={width} transform="rotate(-90)" />} type="primary">
                            Exporter
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </>
    );
}