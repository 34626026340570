import React from "react";

import { Badge, Card, Divider, Space } from "antd";

export const LegendCustom = ({ legend }) => (
    <Card>
        <Space 
            className="d-flex justify-content-center" 
            wrap 
            split={<Divider type="vertical" />}
        >
            {
                legend.map(l => {
                    const { id, name, color } = l;

                    return (
                        <span key={id}>
                            <Badge className="me-1 dot-8" color={color}/>
                            {name}
                        </span>
                    );
                })
            }
        </Space>
    </Card>
)