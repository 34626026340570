import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";

import { useAuth } from "_contexts";
import { objectsAreEquals, shallowCopy } from "_helpers";
import { login } from "_services";

import { 
    Button,
    Card, 
    Col,
    Image,
    Input, 
    message,
    QRCode, 
    Row,
    Space
} from "antd";

import Recouveo from "_assets/recouveo.png";

import styles from "_styles/Login.module.css";

export function Login() {
    const [ componentIsReady, setComponentIsReady ] = useState(false);

    const initialFields = useMemo(() => ({
        login: "",
        password: ""
    }), []);
    const [ fields, setFields ] = useState(initialFields);

    const initialFieldsState = useMemo(() => ({
        login: {
            _isTouched: false,
            _status: null
        },
        password: {
            _isTouched: false,
            _status: null
        }
    }), []);
    const [ fieldsState, setFieldsState ] = useState(initialFieldsState);

    const initialErrors = useMemo(() => ({
        login: "",
        password: ""
    }), []);
    const [ errors, setErrors ] = useState(initialErrors);
    const [ passwordVisible, setPasswordVisible ] = useState(false);

    const formIsSubmittedRef = useRef(false);

    const { setUser } = useAuth();
    const [ messageApi, contextHolder ] = message.useMessage();
    const InputPassword = Input.Password;

    const loginIsTouched = useMemo(() => ( fieldsState.login._isTouched  ), [fieldsState.login]);
    const passwordIsTouched = useMemo(() => ( fieldsState.password._isTouched ), [fieldsState.password]);

    const colImgHeight = useMemo(() => ( 217 ), []);
    const imageWidth = useMemo(() => ( 200 ), []);
    const qrDimension = useMemo(() => ( 64 ), []);

    useEffect(() => {
        document.title="Espace client";

        const timer = setTimeout(() => { // Hack évite l'affichage du composant lors du routage
            setComponentIsReady(true);
        }, 100)

        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {
        handleErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginIsTouched, passwordIsTouched, fields.login, fields.password])

    const handleChange = ({ name, value }) => {
        setFields(fields => ({ ...fields, [name]: value }));
    }

    const handleBlur = ({ name }) => {
        setFieldsState(fieldsState => ({...fieldsState, [name]: {_isTouched: true}}));
    }

    const handleReset = ( ) => {
        setFields(initialFields);
        setFieldsState(initialFieldsState);
        setErrors(initialErrors);
        setPasswordVisible(false);
    } 

    const handleErrors = useCallback(( ) => {
        const formIsSubmitted = formIsSubmittedRef.current; 

        const fieldsCopy = shallowCopy(fields),
            { login, password } = fieldsCopy;

        let tempErrors = shallowCopy(errors), tempFieldsState = shallowCopy(fieldsState);

        if( loginIsTouched || formIsSubmitted ){
            tempErrors['login'] = !login ? 'Le login est un champ obligatoire' : !login.includes('@') ? 'Le login doit être au format utilisateur@domaine' : '';
            tempFieldsState['login']['_status'] = !login ? 'error' : !login.includes('@') ? 'warning' : null;
        } 

        if( passwordIsTouched || formIsSubmitted ){
            tempErrors['password'] = !password ? 'Le mot de passe est un champ obligatoire' : '';
            tempFieldsState['password']['_status'] = !password ? 'error' : null;
        }

        setErrors(tempErrors);
        setFieldsState(tempFieldsState);

        return objectsAreEquals(tempErrors, initialErrors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, fieldsState, errors, loginIsTouched, passwordIsTouched, formIsSubmittedRef.current])
    
    const onSubmit = ( ) => {
        formIsSubmittedRef.current = true;
        const isValid = handleErrors();

        if( isValid ){
            try{
                const timer = setTimeout(async () => {
                    const { response, errorMsg } = await login(fields);

                    if( response ){
                        messageApi.success({key: "validation", content: "Connecté !"});
                        setUser({ isAuth: response });
                    } else{
                        messageApi.error({key: "validation", content: errorMsg});
                    }
                }, 0)

                formIsSubmittedRef.current = false;
                handleReset();

                return () => {
                    clearTimeout(timer);
                } 
            } catch(e){
                throw new Error(e);
            }
        } else {
            messageApi.error({key: "validation", content: "Validation incorrecte, veuillez corectement remplir les champs marqués d'un astérisque"});
        }
    }

    const onKeyUp = ({ key }) => key === 'Enter' && onSubmit();

    return(
        <>
            { contextHolder }
            {
                componentIsReady
                &&
                    <Row gutter={16} justify='center' align='middle' className="h-100">
                        <Col style={{ height: colImgHeight }}>
                            <div className="flex-column" style={{ maxWidth: imageWidth }}>
                                <Image src={Recouveo} preview={false} width={imageWidth} />
                                <Row align='middle'>
                                    <Col>
                                        <QRCode 
                                            value="recouveo.com" 
                                            className={styles.qrCode}
                                            size={qrDimension}
                                            bordered={false}
                                        />
                                    </Col>
                                    <Col flex='auto'>
                                        <span style={{ fontSize: '23px' }}>Espace client</span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col>
                            <Card
                                title="Se connecter"
                                style={{width: "450px"}}
                            >
                                <Space direction="vertical" className="w-100">
                                    <Row>
                                        <Col xs={8}>Login<span style={{color: 'red'}}>*</span> :</Col>
                                        <Col xs={16}>
                                            <Input 
                                                name="login"
                                                onBlur={({ currentTarget }) => handleBlur(currentTarget)}
                                                onChange={({ currentTarget }) => handleChange(currentTarget)}
                                                onKeyUp={onKeyUp}
                                                status={fieldsState?.login?._status}
                                                value={fields.login}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        errors?.login &&
                                            <div style={{color: 'red'}}>{errors.login}</div>
                                    }
                                    <Row>
                                        <Col xs={8}>Mot de passe<span style={{color: 'red'}}>*</span> :</Col>
                                        <Col xs={16}>
                                            <InputPassword 
                                                name="password"
                                                onBlur={({ currentTarget }) => handleBlur(currentTarget)}
                                                onChange={({ currentTarget }) => handleChange(currentTarget)}
                                                onKeyUp={onKeyUp}
                                                status={fieldsState?.password?._status}
                                                value={fields.password}
                                                visibilityToggle={{
                                                    visible: passwordVisible,
                                                    onVisibleChange: setPasswordVisible,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        errors?.password &&
                                            <div style={{color: 'red'}}>{errors.password}</div>
                                    }
                                    <div className="d-flex">
                                        <Button className="ms-auto" type="primary" onClick={onSubmit}>Connexion</Button>
                                    </div>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
            }
        </>
    );
}