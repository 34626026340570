import Bump from "_assets/folderDetail/actions/bump.svg";
import CallIn from "_assets/folderDetail/actions/call-in.svg";
import CallOut from "_assets/folderDetail/actions/call-out.svg";
import Chat from "_assets/folderDetail/actions/chat.svg";
import Checked from "_assets/folderDetail/actions/checked.svg";
import Closed from "_assets/folderDetail/actions/closed.svg";
import EmailIn from "_assets/folderDetail/actions/email-in.svg";
import EmailOut from "_assets/folderDetail/actions/email-out.svg";
import Euro from "_assets/folderDetail/actions/euro.svg";
import Mail from "_assets/folderDetail/actions/mail.svg";
import MailIn from "_assets/folderDetail/actions/mail-in.svg";
import MailOut from "_assets/folderDetail/actions/mail-out.svg";
import Sms from "_assets/folderDetail/actions/sms.svg";

import DefaultFile from "_assets/folderDetail/invoices/messages/default-file.svg";
import JpgFile from "_assets/folderDetail/invoices/messages/jpg-file.svg";
import PdfFile from "_assets/folderDetail/invoices/messages/pdf-file.svg";
import PngFile from "_assets/folderDetail/invoices/messages/png-file.svg";
import XlsFile from "_assets/folderDetail/invoices/messages/xls-file.svg";

export function defineSize(initialSize){
    switch(initialSize){
        case "sm" :
        case "small" :
            initialSize = "small";
            break;
        case "md" :
        case "middle" :
        case "medium" :
            initialSize = "middle";
            break;
        case "lg" :
        case "large" :
            initialSize = "large";
            break;
        default : 
            initialSize = "middle";
            break;
    }

    return initialSize;
}

export function defineBadgeRibbon(activity){
    let text,
        color;

    switch(activity){
        case "high":
            text = "Activité forte";
            color = "red";
            break;
        case "medium": 
            text = "Activité modérée";
            color = "orange";
            break;
        case "low": 
            text = "Activité faible";
            color = "blue";
            break;
        default:
            break;
    }

    return {text, color};
}

export function defineActionsTimelineColor(statusId){
    switch( statusId ){
        case 'S1_OPEN' : statusId = '#538DD5'; break;
        case 'S2P_PAY' : statusId = '#366092'; break;
        case 'S2L_LITIG' : statusId = '#e26b0a'; break;
        case 'S2J_JUDIC' : statusId = '#E2C60D'; break;
        case 'SX_CLOSE' : statusId = '#808080'; break;
        default : statusId = '#fff'; break;
    }

    return statusId;
}

export function defineActionsTimelineIcon(actionId){
    let src = "";

    switch( actionId ){
        case 'AGREE_START' : 
        case 'AGREE_FOLLOW' : 
            src = Euro;
            break;
        case 'BUMP' : 
            src = Bump;
            break;
        case 'CALL_IN' : 
        case 'CALL_RDV' : 
            src = CallIn;
            break;
        case 'CALL_OUT' : 
            src = CallOut;
            break;
        case 'CLOSE_ACK' : 
        case 'CLOSE_ASK' : 
            src = Closed;
            break;
        case 'EMAIL_IN' : 
            src = EmailIn;
            break;
        case 'EMAIL_OUT' : 
            src = EmailOut;
            break;
        case 'JUDIC_START' : 
        case 'JUDIC_FOLLOW' : 
        case 'LITIG_START' : 
        case 'LITIG_FOLLOW' : 
            src = Checked;
            break;
        case "MAIL_AUTO" : 
            src = Mail;
            break;
        case "MAIL_IN" : 
            src = MailIn;
            break;
        case "MAIL_OUT" : 
            src = MailOut;
            break;
        case "SMS_OUT" : 
            src = Sms;
            break;
        case "TCHAT_IN" : 
        case "TCHAT_OUT" : 
            src = Chat;
            break;
        default :
            src = Checked;
            break;
    }

    return src;
}

export function defineImgSrcToDownload( type ) {
    let src;

    switch( type ){
        case 'jpg' : 
        case 'jpeg' : src = JpgFile; break ;
        case 'pdf' : src = PdfFile; break ;
        case 'png' : src = PngFile; break ;
        case 'xls' :
        case 'xlsx' : src = XlsFile; break ;
        default : src = DefaultFile ; break ;
    }

    return src;
}