import React from 'react';

import { 
    Checkbox,
    DatePicker,
    Input,
    Row, 
    Select, 
    Space,
    Typography 
} from 'antd';

// export function Field({
//     name,
//     as,
//     rows,
//     register,
//     placeholder,
//     type,
//     size,
//     value,
//     disabled,
//     onChange,
//     defaultValue,
//     errors,
//     children
// }){
//     return(
//         <Form.Group>
//             <Form.Label htmlFor={name}>{children}</Form.Label>
//             <Form.Control
//                 size={size == "0" ? null : size ? size : "lg"}
//                 placeholder={placeholder}
//                 type={type ? type : "text"}
//                 id={name}
//                 name={name}
//                 as={as}
//                 disabled={disabled}
//                 rows={rows}
//                 value={value}
//                 onChange={onChange}
//                 ref={register}
//                 defaultValue={defaultValue}
//             />
//             {errors && <span className="text-danger">{errors.message}</span>}
//         </Form.Group>
//     );
// }

// export function FieldSet({legend, children}){
//     return(
//         <fieldset>
//             <legend>{legend}</legend>
//             <div className="ml-3">
//                 {children}
//             </div>
//         </fieldset>
//     );
// }

// // React-hook-form v. 6.15.7 resolver 1.3.7
// export function FieldNumberFormat({
//     name, 
//     format, 
//     control, 
//     errors, 
//     children 
// }){
//     return(
//         <Form.Group>
//             <Form.Label htmlFor={name}>{children}</Form.Label>
//             <Controller
//                 control={control}
//                 name={name}
//                 as={NumberFormat}
//                 className="form-control form-control-lg"
//                 format={format}
//                 defaultValue=""
//             />
//             {errors && <span className="text-danger">{errors.message}</span>}
//             {/* <Controller
//                 control={control}
//                 name={name}
//                 defaultValue=""
//                 render={({ onChange, value, name }) => {
//                     return(
//                         <>
//                             <NumberFormat
//                                 className="form-control form-control-lg"
//                                 type="text"
//                                 format={format}
//                                 id={name}
//                                 value={value}
//                                 onChange={onChange}
//                             />
//                             {errors && <span className="text-danger">{errors.message}</span>}
//                         </>
//                     );
//                 }}
//             /> */}
//         </Form.Group>
//     );
// }

// export function Radio({
//     name, 
//     register, 
//     labelOne, 
//     labelTwo, 
//     valueOne, 
//     valueTwo, 
//     errors,
//     children
// }){
//     return(
//         <Form.Group>
//             <Form.Label>
//                 {children}
//                 {errors && <span className="text-danger"> {errors.message}</span>}
//             </Form.Label>
//             <br/>
//             <Form.Check
//                 type="radio"
//                 className="pl-0"
//                 inline
//                 label={labelOne}
//                 id={labelOne}
//                 name={name}
//                 value={valueOne}
//                 ref={register}
                
//             />
//             <Form.Check
//                 type="radio"
//                 inline
//                 label={labelTwo}
//                 id={labelTwo}
//                 name={name}
//                 value={valueTwo}
//                 ref={register}
//             />
//         </Form.Group>
//     );
// }

// export function Switch({
//     labels,
//     register,
//     errors
// }){
//     return(
//         <Form.Group>
//             {labels.map(({label, name}, index) => {
//                 return(
//                     <Fragment key={index}>
//                         <Form.Check type="switch">
//                             <Form.Check.Input
//                                 name={name}
//                                 id={name}
//                                 ref={register}
//                             />
//                             <Form.Check.Label htmlFor={name}>
//                                 {label}<span className="text-danger">*</span>
//                             </Form.Check.Label>
//                         </Form.Check>
//                         {errors[name] && <span className="text-danger">{errors[name].message}</span>}
//                     </Fragment>
//                 );
//             })}
//         </Form.Group>
//     );
// }

// export function File({name, register, src, height, multiple}){
//     return(
//         <Form.File className="text-center" >
//             <Form.File.Label htmlFor={name}>
//                 <Image src={src} alt="iconLabel" style={{height:height}} role="button"/>
//             </Form.File.Label>
//             <Form.File.Input name={name} multiple={multiple ? true : false} ref={register} id={name} hidden/>
//         </Form.File>
//     );
// }

// V.2
const { Text } = Typography;
const { RangePicker } = DatePicker;

const InputWithLabel = ({ label, children }) => (
    <Row className='align-items-center'>
        <Space>
            <Text>{ label } : </Text>
            { children }
        </Space>
    </Row>
)

export const InputByType = ({ type, label = '', name, options = [], mode = undefined, onChange, defaultValue = [], ...props }) => {
    const CheckboxGroup = Checkbox.Group;

    const getInputByType = ( type = "" ) => {
        switch( type.toLowerCase() ) {
            case 'input' :
                return (
                    <Input key={name} name={name} onChange={({ currentTarget }) => onChange(currentTarget)} {...props} />
                );
            case 'select' :
                return (
                    <Select key={name} options={options} mode={mode} maxTagCount={mode === 'multiple' ? 'responsive' : undefined} onChange={(value) => onChange({ name, value })} {...props} />
                );
            case 'rangepicker' :
                return (
                    <RangePicker key={name} onChange={(values) => onChange({ name, value: values })} {...props} />
                );
            case 'checkbox' :
                return (
                    <Checkbox name={name} onChange={({ target }) => onChange(target)} {...props} />
                );
            case 'checkboxgroup' :
                return (
                    <CheckboxGroup 
                        options={options}
                        name={name}
                        defaultValue={defaultValue}
                        onChange={(values) => onChange({name, value: values})}
                    />
                );
            default :
                return (
                    <Input key={name} name={name} onChange={({ currentTarget }) => onChange(currentTarget)} {...props} />
                );
        }
    }

    return (
        <div>
            {
                label 
                ?
                    <InputWithLabel label={label}>
                        { getInputByType(type) }
                    </InputWithLabel>
                :
                    <>
                        { getInputByType(type) }
                    </>
            }
        </div>
    );
}