import React, { useMemo } from "react";

import { useAuth } from "_contexts";
import { isNonEmptyArray } from "_helpers";
import { useMessage } from "_hooks";
import { logout } from "_services";

import { useLocation, useNavigate, Link } from "react-router-dom";
import { Breadcrumb, Col, Dropdown, Row } from 'antd';

import { ReactComponent as Settings } from "_assets/settings/settings-icon.svg";

export const CustomBreadcrumb = ( ) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, setUser } = useAuth();
    const [ Message, contextHolder ] = useMessage();
    
    const pathSnippets = useMemo(() => ( location.pathname.split('/').filter((i) => i) ), [location]);

    const handleNavigate = ( path ) => {
        navigate(path);
    }

    const handleClick = ( ) => {
        try{
            const timer = setTimeout(async () => {
                const { response } = await logout();

                if( response ){
                    Message.success({key: "invalidation", content: "Déconnecté !"});
                    setUser({ isAuth: false });
                }
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
    }

    const breadcrumbNameMap = useMemo(() => ({
        '/dossiers': 'Dossiers',
        '/reporting': 'Reporting',
        '/parametres': 'Paramètres'
    }), [])
    
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

        const variantUrl = url.split('/').filter(i => i);
        const variantUrlLength = variantUrl.length;

        return {
          key: url,
          title: <Link to={url}>{breadcrumbNameMap?.[url] || variantUrl[variantUrlLength-1]}</Link>,
        };
      });
    const dropdownItems = useMemo(() => ({
        items: [{
            key: '0',
            label: (
                <span onClick={ () => handleNavigate('/parametres') }>Paramètres</span>
            ),
            'icon': <Settings height={18} width={18} className="me-1" />
        }, {
            type: 'divider',
        },{
            key: '1',
            label: (
                <span onClick={handleClick}>Se déconnecter</span>
            )
        }]
    }), []) 
    const breadcrumbItems = [
    {
        key: 'accueil',
        title: <Link to="/">Accueil</Link>
    },
    ].concat(extraBreadcrumbItems);

    return (
        <>
            { contextHolder }
            {
                isNonEmptyArray(pathSnippets) && !pathSnippets.includes('login')
                ?
                    <>
                        <Row>
                            <Col>
                                <Breadcrumb items={breadcrumbItems} separator=">"/> 
                            </Col>
                            <Col className="ms-auto">
                                <Dropdown 
                                    menu={dropdownItems}
                                >
                                    <a href ="/" onClick={(e) => e.preventDefault()}>
                                        {user?.user}
                                    </a>
                                </Dropdown>
                            </Col>
                        </Row>
                    </>
                : 
                    <></>
            }
        </>
    );
}