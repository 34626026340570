import { useState, useMemo, useEffect } from "react";

export function useTextarea( textareaRef, value ) {
    const initialHeight = useMemo(() => ( 32 ), []);
    const [ height, setHeight ] = useState(initialHeight);

    useEffect(() => {
        if( textareaRef && textareaRef.style ){
            if( value ){
                const scrollHeight = textareaRef.scrollHeight + 2;
                setHeight(scrollHeight);
            } else {
                setHeight(initialHeight);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textareaRef, value]);

    return height;
}