import { useState, useEffect, useCallback, useMemo } from "react";

import { isNonEmptyArray, isNonEmptyObject, shallowCopy, b64toBlob } from "_helpers";
import { 
    getFolders, 
    getFolderDetail, 
    getFolderMessages, 
    getFolderMessageAttachment,
    sendFolderMessage 
} from "_services";

export function useFolders(){
    const [ gridData, setGridData ] = useState([]);
    const [ chartsData, setChartsData ] = useState({});
    const [ chartsLegend, setChartsLegend ] = useState([]);

    const [ fetchDataAreReady, setFetchDataAreReady ] = useState(false);
    const [ fetchIsDone, setFetchIsDone ] = useState(false);

    const resetData = useCallback(() => {
        setGridData([]);
        setChartsData({});
        setChartsLegend([]);
    }, []);

    useEffect(() => {
        try{
            const timer = setTimeout(async () => {
                const { response, data } = await getFolders();

                if( response ){
                    const { records, charts } = data;

                    setGridData(records);
                    setChartsData({
                        chart01: charts?.chart01 || [],
                        chart02: charts?.chart02 || [],
                    });
                    setChartsLegend(charts?.legend || []);

                    setFetchDataAreReady( (isNonEmptyArray(records) && isNonEmptyObject(charts)) );
                    setFetchIsDone(true);
                } else {
                    resetData();
                    setFetchDataAreReady(false);
                    setFetchIsDone(true);
                }
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        fetchGridData: gridData,
        fetchChartsData: chartsData,
        fetchChartsLegend: chartsLegend,
        fetchDataAreReady,
        fetchIsDone
    }
}

export function useFolderDetail({ id }){
    const [ contactsData, setContactsData ] = useState({});
    const [ invoicesData, setInvoicesData ] = useState({});
    const [ actionsData, setActionsData ] = useState([]);

    const resetData = useCallback(() => {
        setContactsData({});
        setInvoicesData({});
        setActionsData([]);
    }, []);

    useEffect(() => {
        try{
            const timer = setTimeout(async () => {
                const { response, data } = await getFolderDetail({ id });

                if( response ){
                    setContactsData(data?.contacts || {});
                    setInvoicesData(data?.invoices || {});
                    setActionsData(data?.actions || []);
                } else {
                    resetData();
                }
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { 
        fetchContactsData: contactsData,
        fetchInvoicesData: invoicesData, 
        fetchActionsData: actionsData
    };
}

export function useFolderDetailGetMessages( modalIsOpen, values ){
    const [ messages, setMessages ] = useState([]);
    const [ inError, setInError ] = useState(false);

    const doReload = () => {
        try{
            const timer = setTimeout(async () => {
                const { response, data } = await getFolderMessages(values);
                
                if( response ){
                    setMessages(data);
                } else {
                    setInError(true);
                    setMessages([]);
                }
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            setInError(true);
            setMessages([]);
            throw new Error(e);
        }
    }

    useEffect(() => {
        if( modalIsOpen ){
            doReload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen])

    return { 
        inError,
        doReload,
        fetchMessages: messages 
    }
}

export function useFolderDetailGetMessageAttachment( ){
    const [ binId, setBinId ] = useState('');

    useEffect(() => {
        if( binId ){
            try{
                const timer = setTimeout(async () => {
                    const { response, data } = await getFolderMessageAttachment(binId);
                    
                    if( response ){
                        const { bin_64, bin_filename} = data;

                        let bin = b64toBlob(bin_64) ;
                        const url = window.URL.createObjectURL(new Blob([bin]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', bin_filename);
                        document.body.appendChild(link);
                        link.click();
                    }

                    setBinId("");
                }, 0)
    
                return () => clearTimeout(timer);
            } catch(e){
                setBinId("");
                throw new Error(e);
            }
        }
    }, [binId])

    return {
        setBinId
    }
}

export function useFolderDetailSendMessage( payload ){
    const initialValues = useMemo(() => ({
        text: "",
        attachments: []
    }), [])
    const [ values, setValues ] = useState({});
    const [ isSubmitted, setIsSubmitted ] = useState(false);

    const [ successMessage, setSuccessMessage ] = useState("");
    const [ errorMessage, setErrorMessage ] = useState("");

    const handleReset = ( ) => {
        setValues(initialValues);
        setIsSubmitted(false);
        setSuccessMessage("");
        setErrorMessage("");
    }

    useEffect(() => {
        if( isNonEmptyObject(payload) && isNonEmptyObject(values) && isSubmitted ){
            let valuesCopy = shallowCopy(values);
            const { text, attachments } = valuesCopy;

            const formData = new FormData();
            Object.entries(payload).forEach(([key, val]) => {
                formData.append(key, val);
            })
            formData.append('text', text);

            if( isNonEmptyArray(attachments) ){
                attachments.forEach(({ originFileObj })=> formData.append('attachments[]', originFileObj));
            }

            valuesCopy = formData;

            try{
                const timer = setTimeout(async () => {
                    const { response, successMsg, errorMsg } = await sendFolderMessage(valuesCopy);
                    
                    if( response ){
                        setSuccessMessage(successMsg);
                    } else {
                        setErrorMessage(errorMsg);
                    }
                }, 0)

                return () => clearTimeout(timer);
            } catch(e){
                throw new Error(e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload, values, isSubmitted])

    return {
        textareaValuesHandler: {
            values, 
            setValues,
            initialValues
        },
        textareaResetValues: handleReset,
        setSubmit: setIsSubmitted,
        successMessage,
        errorMessage
    }
}