import React, { useEffect, useMemo } from "react";

import { ReactComponent as Attachment } from "_assets/folderDetail/invoices/messages/attachment.svg";

import { Button, Col, Row, Upload } from "antd";

import styles from "_styles/Components/_Textarea.module.css";

export const CustomTextarea = ({ textareaRef, textareaHandler, textareaHeight, pj, setIdToRemove }) => {
    const typesAllowed = useMemo(() => ([
        'image/png',
        'image/jpg',
        'image/jpeg',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/pdf'
    ]), [])

    const { values, setValues, initialValues } = textareaHandler;

    useEffect(() => {
        if( !pj ){
            setValues(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pj])

    const handleChange = ({ name, value }) => {
        setValues(oldVal => ({ ...oldVal, [name]: value }))
    }

    const handleDisableScrolling = ( ) => {
        textareaRef.current.style.overflow = 'hidden';
    }
      
    const handleEnableScrolling = ( ) => {
        textareaRef.current.style.overflow = 'auto';
    }

    return (
        <>
            <Row className={["w-100", styles.rowTextarea].join(" ")}>
                <Col flex='auto'>
                    <textarea 
                        ref={textareaRef} 
                        value={values.text} 
                        rows={1} 
                        name='text'
                        className={styles.textarea} 
                        onChange={({ currentTarget }) => handleChange(currentTarget)} 
                        onMouseEnter={() => handleEnableScrolling()}
                        onMouseLeave={() => handleDisableScrolling()}
                    />
                </Col>
                {
                    pj
                    &&
                        <Col flex='26px' className={textareaHeight > 32 ? 'd-flex align-items-end' : undefined}>
                            <Upload
                                accept="image/*, .pdf, .xlsx"
                                multiple
                                beforeUpload={({ type, uid }) => {
                                    if( !typesAllowed.includes(type) ){
                                        setIdToRemove(uid);
                                        return false;
                                    }
                                    return true;
                                }}
                                onChange={({ fileList }) => handleChange({ name: 'attachments', value: fileList })}
                                fileList={values.attachments}
                                itemRender={() => undefined}
                            >
                                <Button className="w-100 mt-auto" ghost icon={<Attachment height={24} width={24} />} />
                            </Upload>
                        </Col>
                }
            </Row>
        </>
    )
}