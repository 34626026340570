import React, { useMemo, useCallback, useEffect, useRef } from 'react';

import { AgGridCustom as AgGrid } from "_components";
import { GridContext } from "_contexts";
import { isNonEmptyArray, deepCopy } from "_helpers";

import { createPinData, initialColumns, gridOptions } from './_tableConfigs';

import dayjs from "dayjs";

export const CustomReportingGrid = ({ gridRef, payload, interval }) => {
    const { fetchGridData, fetchGridConfig, fetchGridFields} = payload;

    const formatFR = useMemo(() => ( 'DD/MM/YYYY' ), []);

    const columnDefs = useMemo(() => ([
        ...initialColumns,
        ...fetchGridConfig,
        {
            headerName: '', colId: 'terminal', children: [
                { headerName: 'Solde', field: 'amount_reste', type: 'valueColumn' },
            ]
        }
    ]), [fetchGridConfig])

    const timer1 = useRef();

    useEffect(() => {
        return () => {
            clearTimeout(timer1.current);
        }
    }, [])

    useEffect(() => {
        if( isNonEmptyArray(interval) ){
            setHeaderNames();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval])

    const setHeaderNames = useCallback(() => {
        const columnDefsCopy = deepCopy(columnDefs);
        columnDefsCopy.forEach(colDef => {
            const { colId, children } = colDef;
            if( colId === 'initial' ) return ;

            children.forEach(child => {
                const { field } = child;
                if( field.includes('start') ) child.headerName = 'Au ' + dayjs(interval[0]).format(formatFR);
                if( field.includes('variation') ) child.headerName = 'Du ' + dayjs(interval[0]).format(formatFR) + ' au ' + dayjs(interval[1]).format(formatFR) ;
                if( field.includes('end') ) child.headerName = 'Au ' + dayjs(interval[1]).format(formatFR);
            })
        });
        timer1.current = setTimeout(() => {
            gridRef.current.api.setColumnDefs(columnDefsCopy);
        }, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval]);

    return (
        <GridContext.Provider value={{ gridOptions }}>
            <AgGrid
                gridRef={gridRef} 
                columnDefs={columnDefs}
                createPinData={createPinData}
                createPinDataExtendedFields={fetchGridFields}
                fetchData={fetchGridData}
            />
         </GridContext.Provider>
    );
}