import React from "react"

import { Row, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export const CustomSpin = ({ style, message = "...Chargement", className = "", ...rest }) => { 
    const antIcon = ( <LoadingOutlined style={{fontSize: 24}} spin/> );

    const myClass = [
        "flex-column",
        "justify-content-center",
        "align-items-center",
    ].join(" ");

    return(
        <Row className={[myClass, className].join(" ")} style={style} {...rest}>
            <Spin indicator={antIcon}/>
            {message}
        </Row>
    );
}