import React, { useState, useMemo } from "react";

import { useInvoices } from "_contexts";

import { CustomModal as Modal } from "./_Modal";

import { Button, Tooltip } from "antd";

import { ReactComponent as Detail } from "_assets/folderDetail/invoices/detail.svg";

export const CustomModalOpener = ({ ...props }) => {
    const { file_id, _status_closed_void, _status_closed_end, _file_filerecord_id, _acc_id, _notepad_txt_litig } = props;

    const { communicationsState } = useInvoices();

    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    const buttonConfig = useMemo(() => {
        if( _status_closed_void || _status_closed_end ) return { title: 'Historique', background: undefined };
        const commsState = communicationsState?.[_acc_id];

        if( commsState ){
            const index = commsState.findIndex(c => c.file_id == _file_filerecord_id);

            if( index !== -1 ){
                const { communication_state } = commsState[index];
                if( communication_state === 'to_treat' ) return { title: 'A traiter', background: 'bg-green' };
                if( communication_state === 'pending' ) return { title: 'En attente', background: 'bg-orange' };
                if( communication_state === 'new' ) return { title: 'Nouveau', background: 'bg-blue' };
            }
        }

        return { title: '', background: undefined };
    }, [_status_closed_void, _status_closed_end, communicationsState])
    const { title, background } = buttonConfig;
    
    const payload = useMemo(() => ({
        acc_id: _acc_id,
        file_id: file_id
    }), [_acc_id, file_id])

    const handleDetailClick = ( ) => {
        setModalIsOpen(oldVal => !oldVal);
    }

    return (
        <>
            <Tooltip title={title}>
                <Button 
                    className={[
                        "ms-1", 
                        background, 
                        "rad-50"
                    ].join(" ")} 
                    ghost 
                    icon={<Detail width={18} height={18} />} 
                    onClick={handleDetailClick}
                />
            </Tooltip>
            <Modal 
                payload={payload}
                summary={_notepad_txt_litig}
                modalHandler={{
                    modalIsOpen,
                    setModalIsOpen
                }}
            />
        </>
    );
}