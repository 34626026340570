import React, { useState, useEffect, useMemo, useRef } from "react";

import { useViewport } from "_contexts";
import { isNonEmptyArray } from "_helpers";

import { Card, Col, Descriptions, Row, Tabs } from "antd";

import parse from 'html-react-parser';

import styles from "_styles/FolderDetail.module.css";

export function Contacts({ fetchData, contactsColHeight }){
    const [ descriptionsItems, setDescriptionsItems ] = useState([]);

    const [ activeKey, setActiveKey ] = useState("0");

    const [ contactDetailDescriptionsItems, setContactDetailDescriptionsItems ] = useState([]);

    const [ descriptionsHeight, setDescriptionsHeight ] = useState(0);
    const descriptionsRef = useRef();

    const { height } = useViewport();

    const DescriptionsItem = Descriptions.Item;

    const tabsHeight = useMemo(() => {
        if( height < 975 ){
            return 827 - descriptionsHeight;
        }
        return 920 - descriptionsHeight;
    }, [height, descriptionsHeight])

    const labelStyle = useMemo(() => ({ width: '200px' }), []);

    const descriptionsItemsModel = useMemo(() => ({
        'soc_txt': 'Entité',
        'acc_id': 'N° de compte',
        'acc_txt': 'Nom',
        'acc_siret': 'SIRET/SIREN'
    }), [])

    const contactDetailDescriptionsItemsModel = useMemo(() => ({
        'adr_postal': 'Adresse',
        'adr_email': 'E-mail',
        'adr_tel': 'Téléphone'
    }), [])

    const tabsItems = useMemo(() => ([
        {
            key: "0",
            label: "Commentaires",
            children: (
                <Row className="h-100 flex-column">
                    <Col className="h-50">
                        <span>Commentaire reporting</span>
                        <Card className="mt-1 d-flex text-break" style={{ height: "calc(100% - 30px)", overflowY: "auto" }}>
                            { parse(fetchData?.notepad_txt_report || "") }
                        </Card>
                    </Col>
                    <Col className="h-50">
                        <span>Commentaire actions externes</span>
                        <Card className="mt-1 d-flex text-break" style={{ height: "calc(100% - 30px)", overflowY: "auto" }}>
                            { parse(fetchData?.notepad_txt_litig || "") }
                        </Card>
                    </Col>
                </Row>
            )
        },
        {
            key: "1",
            label: "Coordonnées",
            children: (
                <Descriptions column={1} bordered className="h-100">
                    {
                        contactDetailDescriptionsItems.map(item => {
                            const { key, label, children } = item;
                            return (
                                <DescriptionsItem key={key} label={label} labelStyle={labelStyle}>
                                    <span className="d-flex">
                                        {children}
                                    </span>
                                </DescriptionsItem>
                            );
                        })
                    }
                </Descriptions>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [fetchData, contactDetailDescriptionsItems])

    useEffect(() => {
        let tempData = [], tempData2 = [];
        
        Object.entries(fetchData).forEach(([key, value]) => {
            if( descriptionsItemsModel[key] ){
                tempData.push({
                    key,
                    label: descriptionsItemsModel[key],
                    children: value || '[N/C]'
                })
            }

            if( contactDetailDescriptionsItemsModel[key] ){
                tempData2.push({
                    key,
                    label: contactDetailDescriptionsItemsModel[key],
                    children: value || '[N/C]'
                })
            }
        })

        setDescriptionsItems(tempData);
        setContactDetailDescriptionsItems(tempData2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData])

    useEffect(() => {
        if( 
            descriptionsRef.current && 
            descriptionsRef.current.clientHeight &&
            isNonEmptyArray(descriptionsItems) && isNonEmptyArray(contactDetailDescriptionsItems)
        ){
            setDescriptionsHeight(descriptionsRef.current.clientHeight)
        }
    }, [descriptionsRef, descriptionsItems, contactDetailDescriptionsItems])

    const handleTabsChange = ( key ) => {
        setActiveKey(key);
    }

    return (
        <>
            {
                isNonEmptyArray(descriptionsItems) && isNonEmptyArray(contactDetailDescriptionsItems) 
                &&
                    <div className="h-100">
                        <Card className="h-100" bodyStyle={{height: '100%'}}>
                            <div ref={descriptionsRef}>
                                <Descriptions title={("Informations").toUpperCase()} column={1} bordered>
                                    {
                                        descriptionsItems.map(item => {
                                            const { key, label, children } = item;
                                            return (
                                                <DescriptionsItem key={key} label={label} labelStyle={labelStyle}>
                                                    <span className="d-flex">
                                                        {children}
                                                    </span>
                                                </DescriptionsItem>
                                            );
                                        })
                                    }
                                </Descriptions>
                            </div>
                            <Tabs 
                                items={tabsItems} 
                                activeKey={activeKey} 
                                className={styles.contactsTabs} 
                                style={{ height: tabsHeight + 'px' }} 
                                onChange={handleTabsChange}
                            />
                        </Card>
                    </div>
            }
        </>
    );
}