import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { useViewport } from "_contexts";
import { formatNumbers, isNonEmptyArray } from "_helpers";

import { LegendCustom } from "./_Legend";

import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

export const RechartsCustom = ({ fetchData, fetchLegend, panelIsOpen, callBacks }) => {
    const [ readyToDisplay, setReadyToDisplay ] = useState(false);
    const [ sumCharts, setSumCharts ] = useState({
        chart01: "0",
        chart02: 0
    })

    const { onChartSectorClick } = callBacks;
    const { height } = useViewport();
    const timer1 = useRef();

    useEffect(() => {
        return () => {
            clearTimeout(timer1);
        }
    }, [])

    useEffect(() => {
        timer1.current = setTimeout(() => {
            setReadyToDisplay(panelIsOpen);
        }, 0);
    }, [panelIsOpen])

    useEffect(() => {
        calcChartsDataSum();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData])

    const calcChartsDataSum = useCallback(() => {
        if( fetchData?.chart01 && fetchData?.chart02 ){
            let sumChart01 = 0, sumChart02 = 0;

            Object.entries(fetchData).forEach(([key, values]) => {
                values.forEach(v => {
                    const { value } = v;
                    if( key === 'chart01' ) sumChart01+= value;
                    if( key === 'chart02' ) sumChart02+= value;
                })
            })

            setSumCharts({chart01: formatNumbers(sumChart01, 0), chart02: sumChart02});
        }
    }, [fetchData])

    const cx = useMemo(() => ( "50%" ), []);
    const chartsHeight = useMemo(() => {
        if( height <= 944 ) return 820;
        if( height <= 950 ) return 835;
        if( height <= 975 ) return 845;
        return 940;
    }, [height])

    return (
        <>
            {
                readyToDisplay && isNonEmptyArray(fetchData?.chart01) && isNonEmptyArray(fetchData?.chart02) && isNonEmptyArray(fetchLegend)
                &&
                    <div className="py-2" style={{height: chartsHeight}}>
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie 
                                    dataKey="value" 
                                    data={fetchData?.chart01 || []} 
                                    cx={cx} 
                                    cy="21%" 
                                    innerRadius={80} 
                                    outerRadius="55%" 
                                    isAnimationActive={false}
                                    onClick={({ id }) => onChartSectorClick({ statusId: id })}
                                >
                                    {
                                        fetchData?.chart01.map((_, index) => {
                                            return (
                                                <Cell 
                                                    key={"cell-" + index} 
                                                    fill={fetchLegend[index]['color']} 
                                                    stroke="none"
                                                />
                                            );
                                        })
                                    }
                                </Pie>
                                <text
                                    x={cx}
                                    y='41%'
                                    style={{ fontSize: 18, fontWeight: 'bold' }}
                                    scaleToFit={true}
                                    textAnchor='middle'
                                    verticalAnchor='middle'
                                >
                                    Répartition ({ sumCharts.chart01 } €)
                                </text>
                                <Pie 
                                    dataKey="value" 
                                    data={fetchData?.chart02 || []} 
                                    cx={cx} 
                                    cy="78%" 
                                    innerRadius={80} 
                                    outerRadius="55%" 
                                    isAnimationActive={false}
                                    onClick={({ id }) => onChartSectorClick({ statusId: id })}
                                >
                                    {
                                        fetchData?.chart02.map((_, index) => {
                                            return (
                                                <Cell 
                                                    key={"cell-" + index} 
                                                    fill={fetchLegend[index]['color']} 
                                                    stroke="none"
                                                />
                                            );
                                        })
                                    }
                                </Pie>
                                <text
                                    x={cx}
                                    y='48%'
                                    style={{ fontSize: 18, fontWeight: 'bold' }}
                                    scaleToFit={true}
                                    textAnchor='middle'
                                    verticalAnchor='middle'
                                >
                                    Nb dossiers ({ sumCharts.chart02 })
                                </text>
                                <Tooltip 
                                    formatter={(value, name, props) => props.payload._chartId === 'chart01' ? (formatNumbers(value, 0) + ' €') : value}
                                />
                                <Legend 
                                    wrapperStyle={{
                                        bottom: "0.1rem"
                                    }}
                                    content={(props) => (
                                        <LegendCustom 
                                            legend={fetchLegend} 
                                            {...props}
                                        />
                                    )}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
            }
        </>
    );
}
