import React, { useMemo } from "react";

import { Col,Row, Tag } from "antd";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const CustomRangePickerFooter = ({ name, onChange }) => {
    const presets = useMemo(() => ([
        { key: 'last', label: 'Depuis aujourd\'hui', children: 
            [
                { key: '15last', value: [dayjs().add(-15, 'd'), dayjs()], label: '15 derniers jours' },
                { key: '30last', value: [dayjs().add(-30, 'd'), dayjs()], label: '30 derniers jours' },
                { key: '90last', value: [dayjs().add(-90, 'd'), dayjs()], label: '90 derniers jours' }
            ] 
        },
        { key: 'past', label: 'Sur la période', children: 
            [
                { key: 'yearPast', value: [dayjs().subtract(1,'year').startOf('year'), dayjs().subtract(1,'year').endOf('year')], label: 'L\'année dernière' },
                { key: 'monthPast', value: [dayjs().subtract(1,'month').startOf('month'), dayjs().subtract(1,'month').endOf('month')], label: 'Le mois dernier' },
                { key: 'treeMonthsPast', value: [dayjs().subtract(3,'month').startOf('month'), dayjs().subtract(1,'month').endOf('month')], label: 'Les trois derniers mois' }
            ]
        }
    ]), [])

    return (
        <div className="container-fluid mt-1">
            {
                presets.map(p => {
                    const { key, label, children } = p;
                    return (
                        <Row key={key}>
                            <Col xs={24} style={{ lineHeight: '20px' }}>
                                { label }
                            </Col>
                            <Col xs={24}>
                                {
                                    children.map(c => {
                                        const { key, label, value } = c;
                                        return (
                                            <Tag key={key} color="blue" className="mx-1" style={{cursor: "pointer"}} onClick={() => onChange({ name, value })}>
                                                { label }
                                            </Tag>

                                        );
                                    })
                                }
                            </Col>
                        </Row>
                    );
                })
            }
        </div>
    );
}