import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '_contexts';
  
export const PublicRoutes = ({ redirectPath = "/" }) => {
    const { user } = useAuth();
    const location = useLocation();

    return user?.isAuth ? <Navigate to={redirectPath} state={{ from: location }} replace /> : <Outlet/> ;
}

export const ProtectedRoutes = ({ redirectPath = "/login" }) => {
    const { user } = useAuth();
    const location = useLocation();

    return user?.isAuth ? <Outlet/> : <Navigate to={redirectPath} state={{ from: location }} replace /> ;
};