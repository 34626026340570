import { createGetRequest, createPostRequest } from '_helpers';

export async function getSettings( ) {
    return createGetRequest("settings_getData")
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function saveSettings( values ) {
    return createPostRequest("settings_saveData", { config: values })
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg, redirect = false }) => {
            return { response: success, successMsg, errorMsg, redirect };
        })
}