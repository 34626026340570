import React from "react";

import styles from "_styles/Components/_Button.module.css";

import { Link } from "react-router-dom";

export const CustomButton = ({ logout, direction, path = "/", children, ...rest }) => {
    switch( direction ){
        case "top" :
            direction = styles.fromTop;
            break;
        case "left" : 
            direction = styles.fromLeft;
            break;
        case "right" : 
            direction = styles.fromRight;
            break;
        case "center" : 
            direction = styles.fromCenter;
            break;
        case "bottom" :
            direction = styles.fromBottom;
            break;
        default :
            break;
    }
    
    return(
        <>
            {
                logout 
                ?
                    <div style={{height:"70px"}} className={[styles.customBtn,direction].join(" ")} {...rest}>
                        {children}
                    </div>
                :
                    <div style={{height:"70px"}} className={[styles.customBtn,direction,styles.btnWithLink].join(" ")} {...rest}>
                        <Link to={path}>{children}</Link>
                    </div>

            }
        </>
    );
}