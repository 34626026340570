import { 
    formatTextReplaceAccents as formatText,
    compareTextContains as textContains, 
    isNonEmptyArray
} from "_helpers";

import { Tag } from "antd";

const defaultColDef = {
    sortable: true,
    resizable: true,
    type: 'centerAlign',
    pinned: null,
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                filterParams: { 
                    trimInput: true,
                    filterOptions: ['contains', 'notContains'],
                    textCustomComparator: textContains,
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            },
            {
                filter: 'agSetColumnFilter', 
                filterParams: {
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            }
        ]
    },
    cellClassRules: {
        bold: params => params.node.rowPinned
    },
    getQuickFilterText: function(params) {
        return params.colDef.hide ? '' : params.value; 
    },
    menuTabs: ['filterMenuTab', 'columnsMenuTab']
};

export const createPinData = ( rowData, extendedFields ) => {
    let result = {
        amount: 0, 
        amount_pending: 0,
        amount_reste: 0
    };

    result = extendedFields.reduce((acc, val) => {
        if( !acc[val] ){
            acc[val] = 0;
        }
        return acc;
    }, result)
    
    if( isNonEmptyArray(rowData) ){
        rowData.forEach(data => {
            Object.entries(data).forEach(([key, val]) => {
                if( result.hasOwnProperty(key) ){
                    result[key]+= val;
                }
            })
        })
    }

    return [result];
}

export const initialColumns = [
    {
        headerName: "", colId: "initial", children: [
            { headerName: "Id", field: "record_id", hide: true, minWidth: "300" },
            { 
                headerName: "", 
                colId: "colCheck",
                width: 50, 
                lockPosition: "left", 
                pinned: "left", 
                headerCheckboxSelection: true, 
                headerCheckboxSelectionFilteredOnly: true, 
                checkboxSelection: true 
            },
            { headerName: "Entité", field: "entity" }, 
            { headerName: "N° Client", field: "acc_id" }, 
            { headerName: "Nom", field: "acc_name" }, 
            { headerName: "N° Facture", field: "record_ref" },
            { headerName: "Statut", field: "status", width: 120, cellRenderer: ({ node }) => {
                const { data, rowPinned } = node,
                    { _status_color, status } = data;
        
                if( !rowPinned ){
                    return (
                        <div className="d-flex justify-content-center">
                            <Tag color={_status_color}>
                                { status }
                            </Tag>
                        </div>
                    );
                }
            }},
            { headerName: "Sous-statut", field: "substatus", minWidth: 450 }, 
            { headerName: "Date", field: "date_record", type: "dateColumn" }, 
            { headerName: "Date transmission", field: "date_load", type: "dateColumn" }, 
            { headerName: "Date d'échéance", field: "date_value", type: "dateColumn" }, 
            { headerName: "Tranche d'ancienneté", field: "seniority", width: 220 },
            { headerName: "Montant initial", field: "amount", type: "valueColumn" },
            { headerName: "Montant dû", field: "amount_pending", type: "valueColumn" },
        ]
    }
];

export const gridOptions = {
    defaultColDef: defaultColDef,
    rowHeight: 25,
    rowSelection: 'multiple',
    suppressContextMenu: true,
    rowMultiSelectWithClick: true,
    debounceVerticalScrollbar: true,
    rowBuffer: 20,
    cacheQuickFilter: true
};