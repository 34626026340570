import React, { useState, useEffect, useMemo } from "react";

import { useSettings } from '_hooks';

import { message, Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import { SettingsIrrecoverabilityCertificates } from "./_SettingsIrrecoverabilityCertificates";
import { SettingsSendingPreferences } from "./_SettingsSendingPreferences";

export const Settings = () => {
    const [ activeKey, setActiveKey ] = useState('sending');

    const { valuesHandler, irrecCerts, setRequestIsSubmitted, messageHandler } = useSettings();
    const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } = messageHandler;

    const [ messageApi, contextHolder ] = message.useMessage();

    const doRedirect = false;

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Espace client - Paramètres';
        // Fetch user configuration
    }, [])

    useEffect(() => {
        if( doRedirect ) navigate('/login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doRedirect])

    useEffect(() => {
        if( successMessage || errorMessage ){
            if( successMessage ){
                messageApi.success(successMessage);
                setSuccessMessage(null);
            } else if( errorMessage ){
                messageApi.error(errorMessage);
                setErrorMessage(null);
            }
            setRequestIsSubmitted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage, errorMessage])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
          // Perform actions before the component unloads
          e.preventDefault();
          e.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    const tabsItems = useMemo(() => ([
        {
            key: 'gen',
            label: 'Général',
            hidden: true
        },{
            key: 'sending',
            label: 'Préférences d\'envoi',
            children: <SettingsSendingPreferences valuesHandler={valuesHandler} setRequestIsSubmitted={setRequestIsSubmitted} activeKey={activeKey} />
        },{
            key: 'irrec',
            label: 'Mes certificats d\'irrécouvrabilité',
            children: <SettingsIrrecoverabilityCertificates irrecCerts={irrecCerts} />
        }
    ]
        .filter(el => !el.hidden)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [valuesHandler?.values, activeKey, irrecCerts])

    const handleTabChange = ( newKey ) => {
        // Check if values change and display alert message before change tab
        setActiveKey(newKey);
    }

    return (
        <div style={{ height: 'calc(100% - 50px)', marginTop: '20px' }}>
            { contextHolder }
            <Tabs 
                items={tabsItems} 
                tabPosition="left" 
                activeKey={activeKey}
                className="h-100"
                onChange={(key) => handleTabChange(key)}
            />
        </div>
    );
}