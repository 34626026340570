import { 
    Homepage,
    Folders,
    Reporting,
    Settings,
    Login
} from "Pages";

export const publicRoutes = [
    { path: "/login", element: <Login /> }
];

export const protectedRoutes = [
    { path: "/", element: <Homepage /> },
    { path: "/dossiers", element: <Folders /> },
    { path: "/reporting", element: <Reporting /> },
    { path: "/parametres", element: <Settings /> }
];

export const privateRoutes = [

];