import { useState, useEffect, useCallback, useMemo } from "react";

import { useApp } from "_contexts";
import { isNonEmptyArray, deepCopy } from "_helpers";
import { getReportingExtraGrid, getReporting } from "_services";

import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export function useReporting(){
    const [ gridData, setGridData ] = useState([]);
    const [ gridDataIsEmpty, setGridDataIsEmpty ] = useState(false);

    const [ gridConfig, setGridConfig ] = useState([]);
    const [ gridFields, setGridFields ] = useState([]);

    const initialInterval = useMemo(() => ([dayjs().subtract(1,'month').startOf('month'), dayjs().subtract(1,'month').endOf('month')]) , [])
    const [ interval, setInterval ] = useState(initialInterval);

    const [ doRedirect, setDoRedirect ] = useState(false);

    const formatEN = useMemo(() => "YYYY-MM-DD" , [])

    const resetData = useCallback(() => {
        setGridData([]);
        setGridDataIsEmpty(false);
    }, []);

    const resetConfig = useCallback(() => {
        setGridConfig([]);
        setGridFields([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try{
            const timer = setTimeout(async () => {
                const { response, data, redirect } = await getReportingExtraGrid();

                if( response ){
                    const config = data?.config,
                        fields = data?.fields;

                    setGridConfig(config);
                    setGridFields(fields);
                } else {
                    resetConfig();
                }
                setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            resetConfig();
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if( isNonEmptyArray(interval) ){
            try{
                const timer = setTimeout(async () => {
                    const intervalFormatted = interval.map(el => dayjs(el).format(formatEN));

                    const { response, data, redirect } = await getReporting({ interval: intervalFormatted });

                    if( response ){
                        const records = data?.reporting?.records || [];

                        if( isNonEmptyArray(records) ){
                            setGridData(records);
                        } else {
                            setGridDataIsEmpty(true);
                        }

                    } else {
                        resetData();
                    }
                    setDoRedirect(redirect);
                }, 0)
    
                return () => clearTimeout(timer);
            } catch(e){
                resetData();
                throw new Error(e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval])

    return {
        gridPayload: {
            fetchGridData: gridData,
            fetchGridConfig: gridConfig,
            fetchGridFields: gridFields,
        },
        gridDataIsEmpty,
        intervalHandler: {
            interval,
            setInterval
        },
        resetData,
        doRedirect
    }
}

export function useReportingCharts( rowData ) {
    const initialGenChartData = useMemo(() => ([
        { id: "CI", name: "Créances irrécouvrables", value: 0 },
        { id: "AVR", name: "Avoirs", value: 0 },
        { id: "OLD", name: "Paiements antérieurs", value: 0 },
        { id: "STOP", name: "Demande arrêt relance", value: 0 },
        { id: "OTHERS", name: "Autres", value: 0 }
    ]), [])
    const [ genChartData, setGenChartData ] = useState(initialGenChartData);
    const [ genChartTotal, setGenChartTotal ] = useState(0);
    
    const genChartColors = useMemo(() => (["#ff0000", "#d99694", "#65c92a", "#ffc000", "#4f81bd"]), []);
    
    const initialOthChartData = useMemo(() => ([
        { id: "COLLECT", name: "Encaissements", value: 0 },
        { id: "NEGOC", name: "En cours de négociation", value: 0 },
        { id: "EXT", name: "Actions externes", value: 0 },
        { id: "JUDIC", name: "Judiciaire", value: 0 },
        { id: "RETURN", name: "Dossiers clôturés", value: 0 }
    ]), [])
    const [ othChartData, setOthChartData ] = useState(initialOthChartData);
    const [ othChartTotal, setOthChartTotal ] = useState(0);
    
    const othChartColors = useMemo(() => (["#92D050", "#4F81BD", "#FFC000", "#a19c9c", "#FF0000"]), []);
    
    const initialAntChartData = useMemo(() => ([
        { id: "MORE365", name: "Supérieur à 1 an", value: 0 },
        { id: "180TO365", name: "De 180 à 365 jours", value: 0 },
        { id: "90TO180", name: "De 90 à 180 jours", value: 0 },
        { id: "LESS90", name: "Inférieur à 90 jours", value: 0 }
    ]), [])
    const [ antChartData, setAntChartData ] = useState(initialAntChartData);
    const [ antChartTotal, setAntChartTotal ] = useState(0);

    const antChartColors = useMemo(() => (["#FF0000", "#FFC000", "#a19c9c", "#92D050"]), []);

    const { config } = useApp(),
        { OPT_LITIG } = config.cfg_opts;

    const OPT_LITIG_IDS = useMemo(() => (
        OPT_LITIG.reduce((acc, val) => {
            acc.push(val.id);
            return acc;
        }, [])
    ), [OPT_LITIG])

    const resetValues = useCallback(() => {
        setGenChartData(initialGenChartData);
        setGenChartTotal(0);
        setOthChartData(initialOthChartData);
        setAntChartData(initialAntChartData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const calculatingChartsValues = useCallback(() => {
        let tempGenChartData = {}, 
            tempOthChartData = othChartData.reduce((a,v) => { if(!a[v.id]) a[v.id] = 0; return a; },{}),
            tempAntChartData = antChartData.reduce((a,v) => { if(!a[v.id]) a[v.id] = 0; return a; },{});

        rowData.forEach(data => {
            const keys = Object.keys(data).filter(k => k.includes('|end'));
    
            keys.forEach(k => {
                const kSplit = k.split('|')[0];
                if( !tempGenChartData[kSplit] ){
                    tempGenChartData[kSplit] = 0;
                }
                tempGenChartData[kSplit]+= data[k];
            })

            const { status, amount_reste, seniority, amount_pending, _substatus_id } = data;

            if( isNonEmptyArray(OPT_LITIG_IDS) && OPT_LITIG_IDS.includes(_substatus_id) ){
                tempOthChartData['NEGOC'] += amount_reste;
            }

            switch( status ){
                case 'Paiement' :
                    tempOthChartData['COLLECT']+= amount_reste
                    break;
                case 'En-cours':
                case 'Inactif':
                case 'Clôture':
                    tempOthChartData['NEGOC']+= amount_reste;
                    break;
                 case 'Act° ext.':
                    if( isNonEmptyArray(OPT_LITIG_IDS) && !OPT_LITIG_IDS.includes(_substatus_id) ){
                        tempOthChartData['EXT']+= amount_reste;
                    }
                    break;
                case 'Judiciaire':
                    tempOthChartData['JUDIC']+= amount_reste;
                    break;  
                default:
                    break;
            }

            if( seniority >= 0 && seniority < 90 ){
                tempAntChartData['LESS90'] += amount_pending;
            } else if( seniority >= 90 && seniority < 180 ){
                tempAntChartData['90TO180'] += amount_pending;
            } else if( seniority >= 180 && seniority < 365 ){
                tempAntChartData['180TO365'] += amount_pending;
            } else if( seniority >= 365 ){
                tempAntChartData['MORE365'] += amount_pending;
            } else {
                tempAntChartData['MORE365'] += amount_pending;
            }
        })

        // Part 2.

        tempOthChartData['COLLECT']+= tempGenChartData?.['LOCAL'] || 0;
        tempOthChartData['COLLECT']+= tempGenChartData?.['REMOTE'] || 0;
        tempOthChartData['RETURN']+= tempGenChartData?.['DR'] || 0;

        let othChartDataCopy = deepCopy(othChartData);

        for( const el of othChartDataCopy ){
            el.value = tempOthChartData?.[el.id];
        }

        const othChartTotalTemp = othChartDataCopy.reduce((acc, val) => (acc + val.value), 0);

        const OTHERS = Object.values(tempOthChartData).reduce((acc, val) => acc + val, 0);

        // Part 1.

        let genChartDataCopy = deepCopy(genChartData);

        for( const el of genChartDataCopy ){
            if( el.id === 'OTHERS' ){
                el.value = OTHERS;
                continue ;
            } 
            el.value = tempGenChartData?.[el.id];
        }

        const genChartTotalTemp = genChartDataCopy.reduce((acc, val) => (acc + val.value), 0);

        // Part 3.

        let antChartDataCopy = deepCopy(antChartData);

        for( const el of antChartDataCopy ){
            el.value = tempAntChartData?.[el.id];
        }

        const antChartTotalTemp = antChartDataCopy.reduce((acc, val) => (acc + val.value), 0);

        setGenChartData(genChartDataCopy);
        setGenChartTotal(genChartTotalTemp);
        setOthChartData(othChartDataCopy);
        setOthChartTotal(othChartTotalTemp);
        setAntChartData(antChartDataCopy);
        setAntChartTotal(antChartTotalTemp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData])

    useEffect(() => {
        resetValues();
        if( isNonEmptyArray(rowData) ){
            setTimeout(() => {
                calculatingChartsValues();
            },500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData])

    return {
        chartsPayload: {
            genChart: {
                data: genChartData,
                colors: genChartColors,
                total: genChartTotal
            },
            othChart: {
                data: othChartData,
                colors: othChartColors,
                total: othChartTotal
            },
            antChart: {
                data: antChartData,
                colors: antChartColors,
                total: antChartTotal
            }
        }
    };
}