import React from "react";

import styles from "_styles/Components/_ConversationBubble.module.css";

export const CustomConversationBubble = ({ direction = "", bubbleColor, children }) => {
    if( direction ) direction = direction.toLowerCase();
    let customClass = "";
    if( direction === 'in' ){
        customClass+= 'bubbleLeft';
    } else if( direction === 'out' ){
        customClass+= 'bubbleRight';
    }

    return (
        <div 
            className={[styles['bubbleBox'], styles[customClass]].join(" ")} 
            style={{ background: bubbleColor ? bubbleColor : '#9c9a97' }}
        >
            { children }
        </div>
    );
}