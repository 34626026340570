import React, { useState, useEffect, useMemo, useRef } from "react";

import { useViewport } from "_contexts";
import { CustomSpin as Spin } from "_components";
import { isNonEmptyArray, isNonEmptyObject } from "_helpers";
import { useFolderDetail } from "_hooks";

import { Contacts } from "./_Contacts";
import { Invoices } from "./_Invoices";
import { Actions } from "./_Actions";

import { Row, Col } from "antd";

export function FolderDetail({ acc_id }){
    const { fetchContactsData, fetchInvoicesData, fetchActionsData } = useFolderDetail({ id: acc_id });
    
    const { width } = useViewport();

    const [ contactsColHeight, setContactsColHeight ] = useState(0);
    const contactsColRef = useRef();

    const contactsWidth = useMemo(() => {
        return 475;
    }, [])

    const invoicesWidth = useMemo(() => {
        if( width <= 1850 ) return 800;
        if( width <= 1920 ) return 810;

        return 830;
    }, [width])

    const actionsWidth = useMemo(() => {
        return (width - contactsWidth - invoicesWidth - 36); // if for margins and paddings
    }, [width, contactsWidth, invoicesWidth])

    useEffect(() => {
        if( 
            contactsColRef.current && 
            contactsColRef.current.clientHeight &&
            isNonEmptyObject(fetchContactsData) && isNonEmptyObject(fetchInvoicesData) && isNonEmptyArray(fetchActionsData)
        ){
            setContactsColHeight(contactsColRef.current.clientHeight)
        }
    }, [contactsColRef, fetchContactsData, fetchInvoicesData, fetchActionsData])

    return (
        <div className="h-100">
            {
                (isNonEmptyObject(fetchContactsData) && isNonEmptyObject(fetchInvoicesData) && isNonEmptyArray(fetchActionsData)) 
                ?
                    <Row gutter={14} className="h-100 py-2 bg-grey justify-content-center" style={{marginTop: '5px'}}>
                        <Col flex={contactsWidth + 'px'} ref={contactsColRef} className="h-100" style={{maxWidth: contactsWidth + 'px'}}>
                            <Contacts fetchData={fetchContactsData} contactsColHeight={contactsColHeight} />
                        </Col>
                        
                        <Col flex={invoicesWidth + 'px'} className="h-100" style={{maxWidth: invoicesWidth + 'px'}}>
                            <Invoices fetchData={fetchInvoicesData} />
                        </Col>
                        
                        <Col flex={actionsWidth + 'px'} className="h-100" style={{maxWidth: actionsWidth + 'px'}}>
                            <Actions fetchData={fetchActionsData} />
                        </Col>
                    </Row>
                :
                    <Spin className="h-100" />
            }
        </div>
    );
}