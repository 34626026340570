import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";

import { CustomSpin as Spin } from "_components";
import { isNonEmptyArray } from "_helpers";
import { useReporting, useReportingCharts } from "_hooks";

import { Col, Button, Divider, Row } from "antd";
import { Collapse } from "bootstrap";
import { useNavigate } from "react-router-dom";

import { CustomReportingCharts as Charts } from "./_ReportingCharts";
import { CustomReportingGrid as Grid } from "./_ReportingGrid";
import { CustomReportingHeader as Header } from "./_ReportingHeader";

import { ReactComponent as Arrow } from "_assets/left-arrow-white.svg";

import styles from "_styles/Reporting.module.css";

import dayjs from "dayjs";

export const Reporting = ( ) => {
    const [ componentIsReady, setComponentIsReady ] = useState(false);
    const [ subComponentIsReady, setSubComponentIsReady ] = useState(false);

    const [ panelIsOpen, setPanelIsOpen ] = useState(true);

    const { gridPayload, gridDataIsEmpty, intervalHandler, resetData, doRedirect } = useReporting();
    const { chartsPayload } = useReportingCharts(gridPayload?.fetchGridData);

    const { fetchGridData } = gridPayload;
    const { genChart, othChart, antChart } = chartsPayload;

    const navigate = useNavigate();
    const gridRef = useRef();
    const timer1 = useRef();

    const iconsSize = useMemo(() => ({ height: 18, width: 18 }), []);
    const { height, width } = iconsSize;

    const { interval } = intervalHandler;

    useEffect(() => {
        document.title = 'Espace client - Reporting';
        const timer = setTimeout(() => {
            setComponentIsReady(true);
        }, 100)

        return () => {
            clearTimeout(timer);
            clearTimeout(timer1.current);
            setComponentIsReady(false);
            setSubComponentIsReady(false);
        }
    }, [])

    useEffect(() => {
        if( doRedirect ) navigate('/login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doRedirect])

    useEffect(() => {
        if( 
            isNonEmptyArray(fetchGridData) && 
            isNonEmptyArray(genChart.data) && 
            isNonEmptyArray(othChart.data) && 
            isNonEmptyArray(antChart.data)
        ) {
            timer1.current = setTimeout(() => {
                setSubComponentIsReady(true);
            }, 100)
        } 
    }, [fetchGridData, genChart?.data, othChart?.data, antChart?.data])

    const myCollapse = document.getElementById('collapseTarget2');
    
    useEffect(() => {
        if( componentIsReady && subComponentIsReady && myCollapse ){
            const bsCollapse = new Collapse(myCollapse, {toggle: false})
            panelIsOpen ? bsCollapse.show() : bsCollapse.hide()
        }
    }, [myCollapse, componentIsReady, subComponentIsReady, panelIsOpen])

    const today = useMemo(() => ( dayjs().format('YYYYMMDD_HHmmss') ), [])

    const xlsxParams = useCallback(( ) => {
        const columnKeys = gridRef.current.columnApi.getColumns()
            .filter(col => col.getColId() !== 'colCheck' && col.isVisible());

        return {
            fileName: 'Reporting - ' + today,
            sheetName: 'Espace client - Reporting',
            columnKeys: columnKeys,
            onlySelected: isNonEmptyArray(gridRef.current.api.getSelectedRows()),
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef?.current])

    const handleDownload = useCallback(( fileType ) => {
        if( fileType === 'xlsx' ) gridRef.current.api.exportDataAsExcel(xlsxParams())
        if( fileType === 'pdf' ) console.log('pdf')
    }, [xlsxParams])

    return (
        <>
            {
                componentIsReady
                &&
                    <div className='mt-2' style={{ height: 'calc(100% - 32px)' }}>
                        <Header gridRef={gridRef} intervalHandler={intervalHandler} resetData={resetData} onDownload={handleDownload} subComponentIsReady={subComponentIsReady} />
                        {
                            subComponentIsReady
                            ?
                                <Row gutter={16} className='mt-2' style={{ height: 'calc(100% - 42px)' }}>
                                    <Col className='h-100 collapse collapse-horizontal' id='collapseTarget2' flex={panelIsOpen ? '600px' : 'none'}>
                                        <Charts payload={chartsPayload} panelIsOpen={panelIsOpen} />
                                    </Col>
                                    <Col flex='50px' className='d-flex justify-content-center h-100'>
                                        <Row className='flex-column align-items-center'>
                                            <Divider type='vertical' className='mb-2' style={{height: '15px'}}/>
                                                <Button 
                                                    type='primary'
                                                    className={styles.collapseButton}
                                                    shape='circle'
                                                    icon={<Arrow className={styles.svgIcon} height={height} width={width} viewBox='-3 0 20 20' transform={!panelIsOpen ? 'rotate(180)' : undefined}/>}
                                                    onClick={() => setPanelIsOpen(!panelIsOpen)} 
                                                />
                                            <Divider type='vertical' className='mt-2' style={{flex: 1}}/>
                                        </Row>
                                    </Col>
                                    <Col flex='auto'>
                                        <Grid gridRef={gridRef} payload={gridPayload} interval={interval} />
                                    </Col>
                                </Row>
                            :
                            gridDataIsEmpty
                            ?
                                <Row justify='center' align='middle' style={{ height: 'calc(100% - 35px)' }}>Aucunes données à afficher</Row>
                            :
                                <Spin style={{ height: 'calc(100% - 35px)' }} />
                        }
                    </div>
            }
        </>
    );
}