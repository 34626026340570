import { createGetRequest, createPostRequest, createPostWithAttachmentsRequest } from '_helpers';

export async function getFolders() {
    return createGetRequest("folders_getData")
        .then(res => res.data)
        .then(({ success, data, errorMsg }) => {
            return { response: success, data, errorMsg };
        })
}

export async function getFolderDetail({ id }) {
    return createPostRequest("folders_getDetailData", { acc_id: id })
        .then(res => res.data)
        .then(({ success, data, errorMsg }) => {
            return { response: success, data, errorMsg };
        })
}

export async function getFolderMessages( values ) {
    return createPostRequest("folders_getLitigMessages", values)
        .then(res => res.data)
        .then(({ success, data }) => {
            return { response: success, data };
        })
}

export async function getFolderMessageAttachment( binId ) {
    return createPostRequest("folders_getLitigMessageAttachment", { bin_id: binId })
        .then(res => res.data)
        .then(({ success, data, successMsg, errorMsg }) => {
            return { response: success, data, successMsg, errorMsg };
        })
}

export async function sendFolderMessage( values ) {
    return createPostWithAttachmentsRequest("folders_sendLitigMessage", values)
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg }) => {
            return { response: success, successMsg, errorMsg };
        })

}