import { createContext, useContext, useState } from "react";

const AppContext = createContext({});

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [ config, setConfig ] = useState({});

    return(
        <AppContext.Provider value={{ config, setConfig }}>
            {children}
        </AppContext.Provider>
    );
}