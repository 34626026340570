import { useState, useEffect, useCallback, useMemo } from "react";

import { isNonEmpty, shallowCopy } from "_helpers";
import { saveSettings, getSettings } from "_services";

export function useSettings(){
    const initialValues = useMemo(() => ({
        periodicity: 'monthly',
        formats: ['report_pdf', 'report_xlsx', 'ci_pdf', 'ci_xlsx'],
        recipients: [
            'email@test.com'
        ],
        customReporting: []
    }), []);
    const [ values, setValues ] = useState(initialValues);
    const [ irrecCerts, setIrrecCerts ] = useState([]);
    const [ requestIsSubmitted, setRequestIsSubmitted ] = useState(false);

    const [ successMessage, setSuccessMessage ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);

    useEffect(() => {
        doReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if( requestIsSubmitted ) doSave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestIsSubmitted])

    const doSave = useCallback(() => {
        try{
            const timer = setTimeout(async () => {
                const { response, successMsg, errorMsg } = await saveSettings(values);

                if( response ){
                    setSuccessMessage(successMsg);
                } else {
                    setErrorMessage(errorMsg);
                }
                // setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    const doReload = useCallback(() => {
        try{
            const timer = setTimeout(async () => {
                const { response, data, successMsg, errorMsg } = await getSettings();

                if( response ){
                    let config = shallowCopy(initialValues);
                    const dataConfig = data?.config || null,
                        ci = data?.ci || [];
                    if( dataConfig ){
                        for( const property in data.config ){
                            const value = data.config[property];
                            config[property] = value;
                        }
                    }
                    setValues(config);
                    setIrrecCerts(ci);
                    setSuccessMessage(successMsg);
                } else {
                    setErrorMessage(errorMsg);
                }
                // setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        valuesHandler: {
            values,
            setValues
        },
        irrecCerts,
        setRequestIsSubmitted,
        messageHandler: {
            successMessage,
            setSuccessMessage,
            errorMessage,
            setErrorMessage
        }
    }
}
