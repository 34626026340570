import React, { useState, useEffect, useMemo, useRef } from "react";

import { formatNumbers, isNonEmptyArray } from "_helpers";

import { Card, Checkbox, Descriptions, Row, Table } from "antd";

import { columns } from "./_tableConfigs";

import styles from "_styles/FolderDetail.module.css";

export function Invoices({ fetchData }){
    const [ descriptionsItems, setDescriptionsItems ] = useState([]);
    const [ tableDataSource, setTableDataSource ] = useState([]);

    const [ closedInvoicesIsEnable, setClosedInvoicesIsEnable ] = useState(false);
    const [ expandedRowKeys, setExpandedRowKeys ] = useState([]);
    
    const DescriptionsItem = Descriptions.Item;

    const tableRef = useRef();

    const labelStyle = useMemo(() => ( 200 ), []);

    const descriptionsItemsModel = useMemo(() => ({
        'inv_nb_open': 'Nb factures',
        'inv_amount_due': 'Encours total',
        'inv_amount_due_over': 'Encours échu'
    }), [])

    useEffect(() => {
        let tempData = [], tempData2 = [], rowKeys = [];

        Object.entries(fetchData).forEach(([key, value]) => {
            if( descriptionsItemsModel[key] ){
                tempData.push({
                    key,
                    label: descriptionsItemsModel[key],
                    children: key !== 'inv_nb_open' ? formatNumbers(value) + ' €' : value
                })
            }

            if( key === 'records' ){
                value.forEach(val => {
                    const { 
                        file_id, 

                        notepad_txt_litig,

                        status_color, 
                        status_txt, 
                        status_substatus_txt,
                        status_closed_void,
                        status_closed_end,

                        records,
                        _acc_id,
                        _file_filerecord_id,
                        _status_id
                    } = val;

                    if( !closedInvoicesIsEnable && ( status_closed_void || status_closed_end ) ) return ;

                    rowKeys.push(file_id);

                    let recordRef = status_txt;
                    if( status_substatus_txt ) recordRef+= ' - ' + status_substatus_txt;

                    let tempObjFile = {
                        key: file_id,
                        file_id,
                        record_ref: recordRef,
                        date_record: null,
                        amount: null,
                        letter_code: null,
                        _notepad_txt_litig: notepad_txt_litig,
                        _status_color: status_color,
                        _status_closed_void: status_closed_void,
                        _status_closed_end: status_closed_end,
                        _acc_id,
                        _file_filerecord_id,
                        _status_id
                    };

                    if( isNonEmptyArray(records) ){
                        let tempArrRecord = [], sumAmount = 0;

                        records.forEach(rec => {
                            const { 
                                record_id,

                                record_ref,
                                date_record,
                                amount,
                                letter_code
                            } = rec;

                            sumAmount+= amount;

                            tempArrRecord.push({
                                key: record_id,
                                record_ref,
                                date_record,
                                amount,
                                letter_code: letter_code || null,
                                _isLeaf: true
                            });
                        })
                        tempObjFile['amount'] = sumAmount;
                        tempObjFile['children'] = tempArrRecord;
                    }

                    tempData2.push(tempObjFile);
                })
            }
        })

        setDescriptionsItems(tempData);
        setTableDataSource(tempData2);
        setExpandedRowKeys(rowKeys);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, closedInvoicesIsEnable])

    return (
        <>
            {
                isNonEmptyArray(descriptionsItems)
                &&
                    <div className="h-100">
                        <Card className="h-100" bodyStyle={{height: '100%'}}>
                            <Descriptions title={("Pièces").toUpperCase()} column={1} bordered>
                                {
                                    descriptionsItems.map(item => {
                                        const { key, label, children } = item;
                                        return (
                                            <DescriptionsItem key={key} label={label} labelStyle={{ width: labelStyle }}>
                                                <span className="d-flex">
                                                    {children}
                                                </span>
                                            </DescriptionsItem>
                                        );
                                    })
                                }
                            </Descriptions>
                            <Row className="my-1">
                                <Checkbox
                                    checked={closedInvoicesIsEnable}
                                    className="ms-auto"
                                    onChange={() => setClosedInvoicesIsEnable(oldVal => !oldVal)}
                                >
                                    { closedInvoicesIsEnable ? 'Masquer' : 'Afficher' } les pièces lettrées
                                </Checkbox>
                            </Row>
                            <div style={{ height: 'calc(100% - 250px)' }}>
                                <Table 
                                    ref={tableRef}
                                    columns={columns} 
                                    dataSource={tableDataSource} 
                                    className={["mt-2", styles.tableHeight].join(" ")}  
                                    pagination={false}
                                    expandable={{
                                        expandedRowKeys,
                                        onExpandedRowsChange: expandedRows => { setExpandedRowKeys(expandedRows) }
                                    }}
                                    scroll={{
                                        x: '0px',
                                        y: '0px'
                                    }}
                                />
                            </div>
                        </Card>
                    </div>
            }
        </>
    );
}