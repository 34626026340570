import React, { useEffect } from "react";

import { 
    CustomBreadcrumb as Breadcrumb,
    PublicRoutes, 
    ProtectedRoutes
} from "_components";
import { useAuth } from "_contexts";
import { isLogged } from "_services";

import { publicRoutes, protectedRoutes } from "./routes.js";

import { HashRouter as Router, Routes, Route } from "react-router-dom";
import dayjs from "dayjs";

export function App( ){
    const { user, setUser } = useAuth();
    
    useEffect(() => {
        dayjs.locale('fr');
    }, [])

    useEffect(() => {
        try{
            const timer = setTimeout(async () => {
                const { response, data } = await isLogged();
                setUser(oldVal => ({...oldVal, isAuth: response, user: data}));
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.isAuth])

    return(
        <Router>
            <div id="site" className="container-fluid">
                <Breadcrumb/>
                <Routes>              
                    <Route element={<PublicRoutes/>}>
                        {
                            publicRoutes.map((r, i) => {
                                const { path, element } = r;
                                return(
                                    <Route key={i} path={path} element={element}/>
                                );
                            })
                        }
                    </Route> 
                    
                    <Route element={<ProtectedRoutes/>}>
                        {
                            protectedRoutes.map((r, i) => {
                                const { path, element } = r;
                                return(
                                    <Route key={i} path={path} element={element}/>
                                );
                            })
                        }
                    </Route> 

                    <Route path="*" element={<div>Page 404</div>}/>
                </Routes>
            </div>
        </Router>
    );
}